import React, { Component } from 'react';
import OnVisible from 'react-on-visible';

// setDefaultProps({
//     percent: 20
// });

class Animate extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            classes: "animate-elem load-in "
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                classes: "animate-elem load-in visible "
            });
        }, 1)
    }

    render() {
        if( this.props.type === "load" ){
            return(
                <div className={ this.state.classes + ((this.props.classes) ? this.props.classes : "") }>
                    { this.props.children }
                </div>
            )
        } else if ( this.props.type === "wait" ){
            return(
                <div id={ (this.props.id) ? this.props.id : "" } className={ "animate-elem load-in " + ((this.props.wait) ? " visible " : "") + ((this.props.classes) ? this.props.classes : "") }>
                    { this.props.children }
                </div>
            )
        } else {
            return (
                <OnVisible className={ "animate-elem load-in " + ((this.props.classes) ? this.props.classes : "") }>
                    { this.props.children }
                </OnVisible>
            )
        }
    }
}

export default Animate;