import React, { Component } from 'react';
import { Link }             from 'react-router-dom';

// import bgVid                from '../../../assets/video/Bison-Background.mp4';
// import Title                from '../typography/titleSection';
import Button               from '../../buttons/buttons';
import WorkSlider           from '../../pages/work/work-slider';
import NewsCard             from '../../pages/news/news-card';
import ContactDetails       from '../page/contactDetails';
// import HoverSlide           from './hoverSlide';
// import {Link}               from 'react-router-dom';
import Animate              from '../../animation/animate';
import LetterAnim           from '../../animation/letterAnim';
import SecondaryText        from '../typography/secondaryText';
import logo1                from '../../../assets/images/logos/logo-1.svg';
import logo2                from '../../../assets/images/logos/logo-2.svg';
import logo3                from '../../../assets/images/logos/logo-3.svg';

const slideTime = 0.1;

const VideoSlide = (props, state) => {
    if (props.indexId === props.index && !state.loaded) {
        state.loaded = true;
    }

    return(
        <Animate id="initial-slide" type="wait" wait={ props.indexId === props.index } classes="video-slide-anim">
            <div className={"slide " + ((props.indexId === props.index) ? "current-slide " : "") + ((state.loaded) ? "slide-loaded " : "")}>
                <video className="bg-vid" loop autoPlay muted playsInline poster={props.poster_url}>
                    <source src={props.url} type="video/mp4" />
                </video>

                <div className={`inner-slide move-down valign-${props.vAlign} halign-${props.hAlign}`}>
                    <div className="container side-padding">

                        <div className={ "inset-container" }>
                            <SecondaryText
                                white   = {true}
                                text    = {props.secondary}
                            />

                            <div className={ `text-container tsize-${props.textSize}` } dangerouslySetInnerHTML={{__html: props.text}}>
                                {/* <h1 dangerouslySetInnerHTML={{__html: props.text}}></h1> */}
                            </div>

                            {
                                ( props.addButton ) &&
                                <Button
                                    classes = 'left-align hero-button text-button'
                                    link    = 'contact'
                                    text    = 'Get in Touch'
                                />
                            }
                        </div>

                        {/* <Title
                            classes     = "inset-container"
                            text        = {props.text}
                            titleType   = "h1"
                        /> */}
                    </div>
                </div>
                <div className="side-padding full-height scroll-container">
                    <div className="scroll-down-cont">
                        <div className="arrow-cont down-arrow"></div>

                        <SecondaryText
                            text    = "Scroll"
                            white   = {true}
                        />
                    </div>
                </div>
            </div>
        </Animate>
    )
}

const TextSlide = (props, state) => {
    if (props.indexId === props.index && !state.loaded) {
        state.loaded = true;
    }

    return(
        <Animate type="wait" wait={ props.indexId === props.index } classes="brand-slide-anim">
            <div className={"slide about-slide " + ((props.indexId === props.index) ? "current-slide " : "") + ((state.loaded) ? "slide-loaded " : "")}>
                <div className={`inner-slide move-down valign-${props.vAlign} halign-${props.hAlign}`}>
                    <div className="container side-padding">

                        <div className={ "inset-container" }>
                            <SecondaryText
                                white   = {true}
                                text    = {props.secondary}
                            />

                            <div className={ `text-container lg-text` } dangerouslySetInnerHTML={{__html: props.text}}>
                                {/* <h1 dangerouslySetInnerHTML={{__html: props.text}}></h1> */}
                            </div>

                            {
                                ( props.addButton ) &&
                                <Button
                                    classes = 'left-align hero-button text-button'
                                    link    = 'contact'
                                    text    = 'Get in Touch'
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Animate>
    )
}

const BrandSlide = (props, state) => {
    if (props.indexId === props.index && !state.loaded) {
        state.loaded = true;
    }
    return(
        <Animate type="wait" wait={ props.indexId === props.index } classes="brand-slide-anim">
            <div className={"slide brand-slide " + ((props.indexId === props.index) ? "current-slide " : "") + ((state.loaded) ? "slide-loaded " : "")}>
                <div className="inner-slide">
                    <div className="container">

                        <div className="inset-container">
                            <div className="row align-center">
                                <div className="col-1">
                                    <SecondaryText
                                        text    = "Why Us?"
                                        white   = {true}
                                    />
                                </div>

                                <div className="col-2">
                                    <div className={ "title-section md-text" }>
                                        <h2>Trusted By Industry Leaders</h2>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <div className="container"> */}

                        <div className="brand-icon-cont pt-md">
                            <div className="logo-container">
                                <img src={logo1} alt="Supreme"/>
                            </div>
                            
                            <div className="logo-container">
                                <img src={logo2} alt="Hoshi"/>
                            </div>
                            
                            <div className="logo-container">
                                <img src={logo3} alt="Emmac"/>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </Animate>
            
    )
}

const ServicesSlide = (props, state) => {
    if (props.indexId === props.index && !state.loaded) {
        state.loaded = true;
    }

    return(
        <Animate type="wait" wait={ props.indexId === props.index } classes="brand-slide-anim">
            <div className={"slide " + ((props.indexId === props.index) ? "current-slide " : "") + ((state.loaded) ? "slide-loaded " : "")}>
                <div className="inner-slide">
                    <div className="container">
                        <div className="inset-container text-container">
                            <SecondaryText
                                white   = {true}
                                text    = {"Our Services"}
                            />

                            <Link 
                                to = {{
                                    pathname: "services",
                                    state: {
                                        colourMode: "light",
                                    }
                                }}
                                className   =   "mt-sm lg-text services-link"
                            >
                                <h2 className="title-why">Licenses & Legislation</h2>
                            </Link>
                            
                            <Link 
                                to = {{
                                    pathname: "services",
                                    state: {
                                        colourMode: "dark",
                                    }
                                }}
                                className   =   "lg-text mt-xl disp-block services-link"
                            >
                                <h2 className="title-why">GMP Services</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Animate>
    )
}

const WhySlide = (props, state) => {
    if (props.indexId === props.index && !state.loaded) {
        state.loaded = true;
    }

    return(
        <Animate type="wait" wait={ props.indexId === props.index } classes="brand-slide-anim">
            <div className={"slide why-slide " + ((props.indexId === props.index) ? "current-slide " : "") + ((state.loaded) ? "slide-loaded " : "")}>
                <div className="inner-slide">
                    <div className="container">
                        <div className="inset-container">
                            <div className="row ">
                                <div className="col-1">
                                    <SecondaryText
                                        white   = {true}
                                        text    = {"Why Malta"}
                                    />
                                </div>

                                <div className="col-2">
                                    <div className="text-container">
                                        <h2>The most progressive legislation on medical cannabis in Europe</h2>
                                        <h3 className="mt-sm accent-text">Malta’s legislation will aid in the of product distribution through Europe</h3>
                                    </div>
                                
                                    <div className="row mt-lg number-table">
                                        <div className="col-1">
                                            <div className="top-section md-text">
                                                <h3>10+</h3>
                                            </div>

                                            <p className="mt-sm">Over ten international medical cannabis companies applying for a license in Malta in under one year.</p>
                                        </div>
                                        <div className="col-1">
                                            <div className="top-section md-text">
                                                <h3>5+</h3>
                                            </div>

                                            <p className="mt-sm">We’ve successfully setup and aided over 5 international medical cannabis companies.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Animate>
    )
}

const WorkSlide = (props, state) => {    
    if (props.indexId === props.index && !state.loaded) {
        state.loaded = true;
    }
    return(
        <Animate type="wait" wait={ props.indexId === props.index } classes="work-slide-anim">
            <div className={"slide " + ((props.indexId === props.index) ? "current-slide " : "") + ((state.loaded) ? "slide-loaded " : "")}>
                <div className="hero-bg-text">
                    <span><LetterAnim delay={ slideTime } type="wait" wait={ props.indexId === props.index }>Latest Work</LetterAnim></span>
                </div>

                <div className="inner-slide">
                    <WorkSlider
                        transparent = {true}
                    />
                </div>
            </div>
        </Animate>
    )
}

const NewsSlide = (props, state) => {

    if (props.indexId === props.index && !state.loaded) {
        state.loaded = true;
    }
    
    return(
        <Animate type="wait" wait={ props.indexId === props.index } classes="news-slide-anim">
            <div className={"slide " + ((props.indexId === props.index) ? "current-slide " : "") + ((state.loaded) ? "slide-loaded " : "")}>
                <div className="hero-bg-text">
                    <span><LetterAnim delay={ slideTime } type="wait" wait={ props.indexId === props.index }>Latest Work</LetterAnim></span>
                </div>

                <div className="inner-slide">
                    <div className="container">
                        <div className={ "title-section " }>
                            <h2>What's happening in our world</h2>
                        </div>
                        
                        <div className="featured-news-section">
                            {props.news.map( (posts, key) => {
                                var dateObj = new Date(posts.date);

                                var date = new Intl.DateTimeFormat('en-GB', { 
                                    year:       'numeric', 
                                    month:      '2-digit', 
                                    day:        '2-digit',
                                    literal :   "."
                                }).format(dateObj)

                                return (
                                    <NewsCard
                                        key         = {key}
                                        slug        = {posts.slug}
                                        image       = {posts.acf.main_image}
                                        date        = {date.replace(/\//g, ".")}
                                        
                                        // date        = "12.02.2019"
                                        title       = {posts.title.rendered}
                                        categories  = {[posts.acf.post_type, posts.acf.post_category]}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Animate>
    )
}

const ContactSlide = (props, state) => {
    if (props.indexId === props.index && !state.loaded) {
        state.loaded = true;
    }

    return(
        <Animate type="wait" wait={ props.indexId === props.index } classes="contact-details">
            <div className={"slide " + ((props.indexId === props.index) ? "current-slide " : "") + ((state.loaded) ? "slide-loaded " : "")}>
                <div className="hero-bg-text">
                    <span><LetterAnim delay={ slideTime } type="wait" wait={ props.indexId === props.index }>Contact Us</LetterAnim></span>
                </div>

                <div className="inner-slide">
                    <ContactDetails />   
                </div>
            </div>
        </Animate>
    )
}

class Slide extends Component {
    constructor (props) {
        super(props);

        // this.showService - this.showService.bind(this);

        this.state = {
            loaded: false,
        }
    }

    componentDidMount(){
        let initialSlide = document.getElementById("initial-slide");
        initialSlide.classList.remove("visible");
        setTimeout(() => {
            initialSlide.classList.add("visible");
        }, 1500)
    }

    render() {
        var slide;

        switch(this.props.name) {
            case "video":
                slide = VideoSlide(this.props, this.state);
                break;

            case "text":
                slide = TextSlide(this.props, this.state);
                break;

            case "brand":
                slide = BrandSlide(this.props, this.state);
                break;

            case "service":
                slide = ServicesSlide(this.props, this.state);
                break;

            case "work":
                slide = WorkSlide(this.props, this.state);
                break;

            case "news":
                slide = NewsSlide(this.props, this.state);
                break;
            
            case "why":
                slide = WhySlide(this.props, this.state);
                break;

            case "contact":
                slide = ContactSlide(this.props, this.state);
                break;
            
            default:
                slide = ContactSlide(this.props, this.state);
                break;
        }
    
        return (
            slide   
        );
    }
}

export default Slide;
