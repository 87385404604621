import React, { Component } from 'react';

class LetterAnim extends Component {
    constructor(props) {
        super(props);
        
        this.processString     = this.processString.bind(this);
        
        this.state = {
            text: this.props.children,
            processedText: false
        };
    }

    componentDidMount() {
        this.processString();
    }

    processString(){
        if(this.props.animate || this.props.type === "wait"){
            // Putting all characters in Array
            const splitString = this.state.text.split('');

            //  outputting the JSX with an animation-delay
            let createTitle = splitString.map((char, index) => {
                const random    = Math.random() >= 0.5;
                const timing    = ((this.props.delay) ? this.props.delay : 0) + index * 0.1;

                const randomSet = (random) ? "each-char" : "each-char opp-dir";
                const spacer    = (char === " ") ? " spacer" : "";
                const style     = { animationDelay: timing + "s" } ;
                return(
                    <span key={ index } className={ randomSet + spacer } style={ style }>{ char }</span>
                )
            })
            this.setState({ processedText: createTitle });
        } else {
            this.setState({ processedText: this.props.children });
        }
    }

    render() {
        if( this.props.type === "wait" ){
            return (
                <div className={ "letter-anim " + ((this.props.wait) ? " wait-trigger " : "") } >
                    {
                        (this.state.processedText) &&
                        <span>{ this.state.processedText }</span>
                    }
                </div>
            )
        } else {
            return (
                <div className="letter-anim instant-trigger">
                    {
                        (this.state.processedText) &&
                        <span>{ this.state.processedText }</span>
                    }
                </div>
            )
        }
    }
}

export default LetterAnim;