import React, { Component }     from 'react';
// import { withCookies } from 'react-cookie';
// import ReactCSSTransitionGroup  from 'react-addons-css-transition-group';

class LoadingScreen extends Component {

    render() {

        return (           
            <div className={"loading-screen " + ((!this.props.loadingState) ? "active" : "hide")}>
                <div className="inner-text">
                    <div className={"main-logo" + ((!this.props.loadingState) ? "compact" : "" )}>
                        <svg width="145.419" height="20.5" viewBox="0 0 145.419 20.5">
                        <g id="logo-text" data-name="Group 1844" transform="translate(-255.426 -465.522)">
                            <path id="Path_2" data-name="Path 2" d="M255.426,483.435v-5.909c0-2.38.268-3.823,1.1-4.866a5.552,5.552,0,0,1,7.807,0,4.541,4.541,0,0,1,.856,2.005,18.068,18.068,0,0,1,.241,3.422h-3.449a22.633,22.633,0,0,0-.08-2.406,1.95,1.95,0,0,0-.294-.963,1.513,1.513,0,0,0-2.353,0c-.294.428-.374.909-.374,2.193v7.166a3.573,3.573,0,0,0,.374,2.166,1.513,1.513,0,0,0,2.353,0,1.95,1.95,0,0,0,.294-.963,23.169,23.169,0,0,0,.08-2.38h3.449a18.356,18.356,0,0,1-.241,3.449,4.952,4.952,0,0,1-.856,1.978,5.619,5.619,0,0,1-7.807,0C255.693,487.285,255.426,485.815,255.426,483.435Z" transform="translate(0 -3.889)" fill="#fff"/>
                            <path id="Path_3" data-name="Path 3" d="M300.551,486.3h-3.182l-.375,3.9h-3.529q1.084-8.662,2.755-18.422h5.454q1.484,8.3,2.807,18.422h-3.556Zm-.321-2.888-.241-1.925c-.187-1.658-.481-3.823-.936-6.5h-.294c-.455,3.182-.722,5.348-.829,6.524l-.241,1.9Z" transform="translate(-26.73 -4.395)" fill="#fff"/>
                            <path id="Path_4" data-name="Path 4" d="M337.083,471.778h2.941l2.593,6.818c.615,1.578,1.1,2.941,1.47,4.118h.08c-.054-1.471-.08-3.021-.08-4.626v-6.31h3.155V490.2h-2.861l-2.673-6.979c-.508-1.31-1.016-2.674-1.47-4.117h-.08c.027.989.054,2.514.054,4.626V490.2h-3.128Z" transform="translate(-57.38 -4.396)" fill="#fff"/>
                            <path id="Path_5" data-name="Path 5" d="M380.069,471.78h2.941L385.6,478.6c.615,1.577,1.1,2.941,1.47,4.117h.08c-.054-1.471-.08-3.021-.08-4.626v-6.31h3.155V490.2h-2.861l-2.674-6.979c-.508-1.31-1.016-2.674-1.47-4.117h-.081c.027.989.054,2.513.053,4.626V490.2h-3.128Z" transform="translate(-87.587 -4.398)" fill="#fff"/>
                            <path id="Path_6" data-name="Path 6" d="M429.538,485.844h-5.294c-.455,2.112-.8,3.743-1.016,4.866H421.7c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208h-1.551Zm-.267-1.284-.856-4.01-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-116.845 -4.905)" fill="#fff"/>
                            <path id="Path_7" data-name="Path 7" d="M466.129,472.5h1.364l4.2,9.946c.989,2.38,1.684,4.091,2.058,5.16h.053c-.08-2.139-.107-3.957-.107-5.481V472.5h1.417v18.208h-1.364l-4.3-10.214c-.855-2.032-1.5-3.663-1.951-4.92h-.08c.08,1.417.107,3.262.107,5.481v9.652h-1.39Z" transform="translate(-148.062 -4.906)" fill="#fff"/>
                            <path id="Path_8" data-name="Path 8" d="M513.711,485.848h-5.294c-.455,2.112-.8,3.743-1.016,4.866h-1.524c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208H514.7Zm-.268-1.283-.855-4.011-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-175.994 -4.908)" fill="#fff"/>
                            <path id="Path_9" data-name="Path 9" d="M550.3,472.507h1.5v16.9h5.508v1.31H550.3Z" transform="translate(-207.211 -4.908)" fill="#fff"/>
                            <path id="Path_10" data-name="Path 10" d="M574.63,484c-1.31-3.663-2.593-7.486-3.823-11.5h1.577c.829,2.941,1.818,6.177,2.994,9.706h.08c1.043-3.289,2.006-6.524,2.915-9.705H579.9c-1.283,4.358-2.54,8.181-3.77,11.523v6.685h-1.5Z" transform="translate(-221.621 -4.909)" fill="#fff"/>
                            <path id="Path_11" data-name="Path 11" d="M609.623,473.846h-3.476v-1.337h8.422v1.337H611.12v16.871h-1.5Z" transform="translate(-246.455 -4.91)" fill="#fff"/>
                            <path id="Path_12" data-name="Path 12" d="M643.466,472.511h1.5v18.208h-1.5Z" transform="translate(-272.679 -4.911)" fill="#fff"/>
                            <path id="Path_13" data-name="Path 13" d="M660.462,484.225V478c0-2.38.24-3.77,1.016-4.759a4.559,4.559,0,0,1,6.657,0c.776.963,1.043,2.246,1.043,4.465h-1.5c0-1.845-.187-2.807-.749-3.556a2.778,2.778,0,0,0-4.251,0c-.535.722-.722,1.738-.722,3.877V484.2c0,2.139.187,3.155.722,3.877a2.465,2.465,0,0,0,2.112.963,2.515,2.515,0,0,0,2.139-.963,5.862,5.862,0,0,0,.749-3.556h1.5c0,2.246-.321,3.529-1.043,4.465a4.558,4.558,0,0,1-6.657,0C660.7,488,660.462,486.6,660.462,484.225Z" transform="translate(-284.623 -4.407)" fill="#fff"/>
                            <path id="Path_14" data-name="Path 14" d="M704.538,485.856h-5.294c-.455,2.112-.8,3.743-1.016,4.866H696.7c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208h-1.551Zm-.267-1.283-.856-4.011-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-310.09 -4.913)" fill="#fff"/>
                            <path id="Path_15" data-name="Path 15" d="M736.531,468.156a1.7,1.7,0,1,1-3.4,0v-.851a1.7,1.7,0,1,1,3.4,0Zm-.311-.825c0-.984-.476-1.53-1.4-1.53s-1.384.54-1.384,1.53v.8c0,.99.463,1.53,1.384,1.53s1.4-.546,1.4-1.53Zm-.743,1.593h-.311c-.032-.1-.089-.222-.14-.368-.063-.178-.139-.368-.222-.578h-.254v.946h-.3v-2.412h.559c.451,0,.654.178.654.66v.153c0,.374-.121.552-.381.622Zm-.927-1.2h.273c.26,0,.343-.127.343-.413v-.121c0-.3-.082-.419-.343-.419h-.273Z" transform="translate(-335.686)" fill="#fff"/>
                        </g>
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingScreen;