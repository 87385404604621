import React, { Component } from 'react';
import OnVisible from 'react-on-visible';

// import BlockImage from '../assets/images/azimut-yacht-image.jpg';

class FadeIn extends Component {
    constructor(props) {
        super(props);
  
        // this.handleScroll  = this.handleScroll.bind(this);

        this.state = {
            phrase: this.props.children
        };
    }
    render(){

        var totalWord;

        if (this.props.wordAnim && this.props.children){
            // Turn string into an array with each character
            var letters;
            var letterArr = this.state.phrase;

            if (letterArr.constructor === Array){
                letters = letterArr.filter((letter) => {
                    if(letter !== " "){
                        return letter;
                    } else {
                        return null;
                    }
                });
                letters = letters[0];
                
            } else {
                letters = letterArr;
            }
            
            letters = letters.split(' ');
            totalWord = letters.map((letter, index) => {
                return(
                    <span className="each-word" style={{transitionDelay: index/this.props.differentRate + this.props.delay + "s"}}> {letter} </span>
                )
            });
        } else {
            totalWord = this.props.children;
        }

        return(
            <OnVisible className={((this.props.wordAnim) ? "word-anim " : "fade-in ") + this.props.classes } percent={ "50%" }>
                { totalWord }
            </OnVisible>
        )
    }
}

FadeIn.defaultProps = {
    classes: "",
    delay: 0,
    differentFade: 20,
    differentRate: 20
};

export default FadeIn;