import React, { Component } from 'react';

// import footerLogo           from '../../assets/images/s7-footer.svg';
import { Link }             from 'react-router-dom';
import DataApi              from '../../utils/data-api';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true
        };
    }

    componentDidMount() {
        // var fetchContactData = DataApi.getOptions();

        // Promise.resolve(fetchContactData).then( response => {
        //     this.setState({
        //         contact:   response.acf,
        //     })
        // }).then( () => {
        //     this.setState({
        //         loaded: true,
        //     })
        // });
    }
        
    render() {
        return (
            <footer>
                <section className="inner-footer">
                    <div className="full-container">
                        <div className="container">
                            <div className="content-container">
                                <div className="foot-cont">
                                    <div className="footer-logo">
                                        <div className="main-logo">
                                            <svg width="145.419" height="20.5" viewBox="0 0 145.419 20.5">
                                            <g id="logo-text" data-name="Group 1844" transform="translate(-255.426 -465.522)">
                                                <path id="Path_2" data-name="Path 2" d="M255.426,483.435v-5.909c0-2.38.268-3.823,1.1-4.866a5.552,5.552,0,0,1,7.807,0,4.541,4.541,0,0,1,.856,2.005,18.068,18.068,0,0,1,.241,3.422h-3.449a22.633,22.633,0,0,0-.08-2.406,1.95,1.95,0,0,0-.294-.963,1.513,1.513,0,0,0-2.353,0c-.294.428-.374.909-.374,2.193v7.166a3.573,3.573,0,0,0,.374,2.166,1.513,1.513,0,0,0,2.353,0,1.95,1.95,0,0,0,.294-.963,23.169,23.169,0,0,0,.08-2.38h3.449a18.356,18.356,0,0,1-.241,3.449,4.952,4.952,0,0,1-.856,1.978,5.619,5.619,0,0,1-7.807,0C255.693,487.285,255.426,485.815,255.426,483.435Z" transform="translate(0 -3.889)" fill="#fff"/>
                                                <path id="Path_3" data-name="Path 3" d="M300.551,486.3h-3.182l-.375,3.9h-3.529q1.084-8.662,2.755-18.422h5.454q1.484,8.3,2.807,18.422h-3.556Zm-.321-2.888-.241-1.925c-.187-1.658-.481-3.823-.936-6.5h-.294c-.455,3.182-.722,5.348-.829,6.524l-.241,1.9Z" transform="translate(-26.73 -4.395)" fill="#fff"/>
                                                <path id="Path_4" data-name="Path 4" d="M337.083,471.778h2.941l2.593,6.818c.615,1.578,1.1,2.941,1.47,4.118h.08c-.054-1.471-.08-3.021-.08-4.626v-6.31h3.155V490.2h-2.861l-2.673-6.979c-.508-1.31-1.016-2.674-1.47-4.117h-.08c.027.989.054,2.514.054,4.626V490.2h-3.128Z" transform="translate(-57.38 -4.396)" fill="#fff"/>
                                                <path id="Path_5" data-name="Path 5" d="M380.069,471.78h2.941L385.6,478.6c.615,1.577,1.1,2.941,1.47,4.117h.08c-.054-1.471-.08-3.021-.08-4.626v-6.31h3.155V490.2h-2.861l-2.674-6.979c-.508-1.31-1.016-2.674-1.47-4.117h-.081c.027.989.054,2.513.053,4.626V490.2h-3.128Z" transform="translate(-87.587 -4.398)" fill="#fff"/>
                                                <path id="Path_6" data-name="Path 6" d="M429.538,485.844h-5.294c-.455,2.112-.8,3.743-1.016,4.866H421.7c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208h-1.551Zm-.267-1.284-.856-4.01-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-116.845 -4.905)" fill="#fff"/>
                                                <path id="Path_7" data-name="Path 7" d="M466.129,472.5h1.364l4.2,9.946c.989,2.38,1.684,4.091,2.058,5.16h.053c-.08-2.139-.107-3.957-.107-5.481V472.5h1.417v18.208h-1.364l-4.3-10.214c-.855-2.032-1.5-3.663-1.951-4.92h-.08c.08,1.417.107,3.262.107,5.481v9.652h-1.39Z" transform="translate(-148.062 -4.906)" fill="#fff"/>
                                                <path id="Path_8" data-name="Path 8" d="M513.711,485.848h-5.294c-.455,2.112-.8,3.743-1.016,4.866h-1.524c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208H514.7Zm-.268-1.283-.855-4.011-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-175.994 -4.908)" fill="#fff"/>
                                                <path id="Path_9" data-name="Path 9" d="M550.3,472.507h1.5v16.9h5.508v1.31H550.3Z" transform="translate(-207.211 -4.908)" fill="#fff"/>
                                                <path id="Path_10" data-name="Path 10" d="M574.63,484c-1.31-3.663-2.593-7.486-3.823-11.5h1.577c.829,2.941,1.818,6.177,2.994,9.706h.08c1.043-3.289,2.006-6.524,2.915-9.705H579.9c-1.283,4.358-2.54,8.181-3.77,11.523v6.685h-1.5Z" transform="translate(-221.621 -4.909)" fill="#fff"/>
                                                <path id="Path_11" data-name="Path 11" d="M609.623,473.846h-3.476v-1.337h8.422v1.337H611.12v16.871h-1.5Z" transform="translate(-246.455 -4.91)" fill="#fff"/>
                                                <path id="Path_12" data-name="Path 12" d="M643.466,472.511h1.5v18.208h-1.5Z" transform="translate(-272.679 -4.911)" fill="#fff"/>
                                                <path id="Path_13" data-name="Path 13" d="M660.462,484.225V478c0-2.38.24-3.77,1.016-4.759a4.559,4.559,0,0,1,6.657,0c.776.963,1.043,2.246,1.043,4.465h-1.5c0-1.845-.187-2.807-.749-3.556a2.778,2.778,0,0,0-4.251,0c-.535.722-.722,1.738-.722,3.877V484.2c0,2.139.187,3.155.722,3.877a2.465,2.465,0,0,0,2.112.963,2.515,2.515,0,0,0,2.139-.963,5.862,5.862,0,0,0,.749-3.556h1.5c0,2.246-.321,3.529-1.043,4.465a4.558,4.558,0,0,1-6.657,0C660.7,488,660.462,486.6,660.462,484.225Z" transform="translate(-284.623 -4.407)" fill="#fff"/>
                                                <path id="Path_14" data-name="Path 14" d="M704.538,485.856h-5.294c-.455,2.112-.8,3.743-1.016,4.866H696.7c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208h-1.551Zm-.267-1.283-.856-4.011-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-310.09 -4.913)" fill="#fff"/>
                                                <path id="Path_15" data-name="Path 15" d="M736.531,468.156a1.7,1.7,0,1,1-3.4,0v-.851a1.7,1.7,0,1,1,3.4,0Zm-.311-.825c0-.984-.476-1.53-1.4-1.53s-1.384.54-1.384,1.53v.8c0,.99.463,1.53,1.384,1.53s1.4-.546,1.4-1.53Zm-.743,1.593h-.311c-.032-.1-.089-.222-.14-.368-.063-.178-.139-.368-.222-.578h-.254v.946h-.3v-2.412h.559c.451,0,.654.178.654.66v.153c0,.374-.121.552-.381.622Zm-.927-1.2h.273c.26,0,.343-.127.343-.413v-.121c0-.3-.082-.419-.343-.419h-.273Z" transform="translate(-335.686)" fill="#fff"/>
                                            </g>
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="footer-text col-4">
                                        <nav className="footer-navigation">
                                            <ul>
                                                <li>
                                                    <Link to="/">Home</Link>
                                                </li>

                                                <li>
                                                    <Link to="/about">About</Link>
                                                </li>

                                                <li>
                                                    <Link to="/services">Services</Link>
                                                </li>
                                                
                                                <li>
                                                    <Link to="/contact">Contact</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                                <div className="foot-bottom foot-cont">
                                    <div className="col">
                                        <span>&copy; {new Date().getFullYear()} Cannanalytica. All rights reserved.</span>
                                    </div>

                                    <div className="col">
                                        <div className="bison-footer">
                                            <a link="_target" href="https://bison.studio/">
                                                <svg viewBox="0 0 172.9 42.3">
                                                <g id="Layer_2">
                                                    <g id="Layer_1-2">
                                                        <g id="detail">
                                                            <path fill="#575757" d="M75.1,41.6h-6.7V10.5h7.2v10.3c1.6-1.5,3.8-2.4,6-2.3c6.6,0,10.7,4.9,10.7,11.9s-4.6,11.9-11.1,11.9
                                                                c-2.3,0.1-4.5-0.8-6.1-2.3V41.6z M80.2,35.9c2.9,0,4.9-2.2,4.9-5.5s-1.9-5.6-4.9-5.6s-4.9,2-4.9,5.6
                                                                C75.3,33.4,77.1,35.9,80.2,35.9z"/>
                                                            <path fill="#575757" d="M94.8,13.7c-0.1-2.2,1.7-4,3.9-4.1c2.2-0.1,4,1.7,4.1,3.9c0.1,2.2-1.7,4-3.9,4.1h-0.1
                                                                c-2.1,0.1-3.9-1.6-4-3.7V13.7z M102.5,41.6h-7.2V18.9h7.2V41.6z"/>
                                                            <path fill="#575757" d="M105.2,38.3l4.1-3.9c1.3,1.3,3.1,2,5,2c1.4,0,2-0.4,2-1.2c0-2.6-10.4-1.5-10.4-9.7c0-4.4,3.8-7,8.7-7
                                                                c3.2-0.1,6.2,1.3,8.1,3.9l-4.7,3.7c-1-1.1-2.3-1.7-3.8-1.8c-1,0-1.6,0.4-1.6,1.1c0,2.9,10.4,1.2,10.4,9.4c0,4.6-4.1,7.5-9.2,7.5
                                                                C110.5,42.3,107.3,40.9,105.2,38.3z"/>
                                                            <path fill="#575757" d="M137,18.5c7.3,0,12.4,5,12.4,11.9s-5.3,11.9-12.4,11.9s-12.3-5-12.3-11.8c0-6.6,5.3-12,11.8-12
                                                                C136.7,18.5,136.8,18.5,137,18.5z M137,35.9c2.9,0,5.2-2.4,5.2-5.3c0,0,0,0,0,0v-0.3c0.2-2.8-2-5.3-4.8-5.5c0,0,0,0,0,0H137
                                                                c-3,0-5.1,2.3-5.1,5.6S134,35.9,137,35.9z"/>
                                                            <path fill="#575757" d="M165.7,28.1c0-2.1-1.2-3.2-3-3.2c-1.6-0.1-3,1.1-3.1,2.7c0,0.1,0,0.2,0,0.2v13.9h-7.2V19h7v2.3
                                                                c1.3-1.9,3.4-3,5.7-2.9c7.1,0,7.8,6,7.8,10.7v12.6h-7.2V28.1z"/>
                                                            <path fill="#575757" d="M27.9,34.3c0.5,0.7,1.4,1,2.2,0.9c-0.3,0.4-0.9,0.6-1.4,0.6c0,0,0.8,0.4,1.2,0.2c0,0-0.7,0.8-2.7,0.5"/>
                                                            <path fill="#575757" d="M1.2,25.6c1.6,1.6,1.8,2.3,1.7,2.6c0,0.6,0.2,2.2,0.2,2.2c0.2,0.5,0.7,0.9,1.3,0.9h0.7
                                                                c0.1,0,0.3,0.1,0.3,0.2c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.3,0.4c0.4,0.1,1.2-0.2,1.2,0.3v1.3c0,0.3,0.2,0.5,0.4,0.6
                                                                c0.8,0.2,1.4,0.8,1.6,1.6c0,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0.4-1,1.8-4.3,3.5-4.3c1-0.1,2,0.4,2.5,1.3L15,37
                                                                c0,0-0.1,0.8,0.2,1.1c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0.4-0.4,2.5,0,3l2.2-1c0.3-0.2-0.5-0.4-0.5-1s0.3-1.3,0.1-1.5
                                                                c-0.1-0.1-0.2-0.2-0.1-0.4c0-0.1,0.1-0.1,0.1-0.1l0,0c1-1,2.3-1.7,3.7-2c2.4-0.6,2.5-2,2.5-2.5c0.1,0.6,0.3,3.3,0.9,3.9
                                                                c0.6,0.6,0.7,1.6,0.3,2.4c-0.5,0.9-1.5,2.8-1.5,2.8h2.8l-0.2-0.5c-0.2-0.4,0-0.8,0.3-1.1h0.1c0.5-0.2,0.8-0.8,0.7-1.3
                                                                c0,0-0.4-1.1,0.3-0.6c0.2,0.1,0.3,0.1,0.5,0c0.3-0.5,0.6-1,0.8-1.5v-0.3l-0.2-0.7v-0.4l0.2-0.4c0.1-0.2,0.1-0.3,0-0.5
                                                                c-0.2-0.4-0.5-0.9-0.8-1.2c-0.7-0.7-0.3-2.4,0-2.8c0.1-0.1,0.2-0.2,0.3-0.1c1.2,0.2,7.3,1.2,8.4,1c1.1-0.3,2.2-0.8,3.2-1.5
                                                                c0.3-0.2,0.8-0.1,1,0.2c0,0.1,0.1,0.2,0.1,0.3c0.3,1.6,0.8,4.5,0.5,5.5c-0.3,1.2-2.4,3.6-2.8,4.1c0,0.1-0.1,0.1-0.2,0.1
                                                                c-0.4,0.2-1.7,1-1.7,1.6h2.7v-0.3c0-0.1,0-0.1,0.1-0.1h0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.3-0.1,2.3-4.9,3.5-5.2s0.8-1.9,0.8-1.9
                                                                c-0.2-1.2-0.1-2.4,0.3-3.6c0.2-0.3,0.5-0.4,0.8-0.3c0.1,0,0.1,0.1,0.2,0.1l1.5,1.6c0.5,0.4,1,0.7,1.5,1c0.4,0.2,0.7,0.6,0.9,1
                                                                l1.4,4l-1.1,2.4H52c0.4-0.5,0.4-1.1,0.2-1.7V39h0.3c0.1,0,0.1,0,0.1-0.1v-0.1c-1.3-2.1-2.1-4.4-2.1-6.9c0,0-0.1-1-0.7-1
                                                                c-0.4,0-1.1-0.8-1.6-1.5c-0.3-0.4-0.4-0.8-0.4-1.3v-1.6c0-0.3,0.1-0.6,0.2-0.9c0.3-0.7,1-2.3,0.7-3.3c-0.1-0.2-0.1-0.4-0.2-0.5
                                                                s-0.1-0.3-0.2-0.5c-0.3-0.6-0.7-1.1-1.2-1.6h-0.4c-0.1-0.1-0.2-0.2-0.4-0.3l-2.5-1.6l-0.3-0.2c0,0-2.2-1.8-3.6-1.7
                                                                c-1.1,0-5.9-2.6-7.6-3.6c-0.4-0.3-0.9-0.5-1.4-0.6c-2.1-0.7-8.2-2.6-9.7-2.1c-1.8,0.4-3.6,0.5-5.4,0.4c-0.5,0-0.9,0.2-1.2,0.5
                                                                c-1.4,1.2-2.6,2.6-3.7,4.1c-0.2,0.3-0.6,0.5-1,0.5l-2.7-0.4c-0.4,0-0.8,0-1.2,0.1c0.8,1,1.3,2.2,1.5,3.4c0.2,1.4-0.8,2.7-2.2,2.9
                                                                l0,0c-0.2,0-2.9-0.1-1.1-2.6c0.5-0.9,0.7-2.1,0.4-3.1c-0.5,0.3-0.9,0.7-1.3,1.2c-1.1,1.5-2,2.6-2.5,3.2c-0.4,0.4-0.6,0.9-0.7,1.5
                                                                C-0.3,22.8,0.1,24.4,1.2,25.6z"/>
                                                            <path fill="#575757" d="M20.9,36.6c-0.5-0.8-1.3-1.1-2.2-0.9c-0.3,0.1,0.3-0.6-0.1-0.6c0,0,2.8-0.2,2.7-1.2S20.9,36.6,20.9,36.6z"/>
                                                            <path fill="#575757" d="M58.3,24.1c0,0-0.2,0.4-1,0.3s-1-1.2-2.6-0.1c-1.1,1.1,0.4,1.6,0.4,1.6S57,26.8,58.3,24.1z"/>
                                                            <path fill="#575757" d="M2.5,10.8C3.3,12.4,4,14,4.4,15.7c0.5-0.3,1-0.5,1.5-0.6c-0.9-1.6-2-3-3.2-4.4C2.6,10.6,2.5,10.7,2.5,10.8
                                                                C2.5,10.6,2.5,10.7,2.5,10.8z"/>
                                                            <path fill="#575757" d="M43.9,18v0.9c1.4,1.1,2.8,2.1,4.3,3c5.2,3.1,10.1,4.5,5.6,2.3c-2.5-1.2-4.8-2.7-7-4.4h-0.1
                                                                c-0.1-0.1-0.2-0.2-0.4-0.3L43.9,18z"/>
                                                            <g enableBackground="new">
                                                                <path fill="#575757" d="M121.3,8c0,1.5-1.2,2.5-2.8,2.5h-3.6V1.8h3.4c1.5,0,2.7,1,2.7,2.5c0,0.7-0.3,1.3-0.8,1.7
                                                                    C120.9,6.4,121.3,7.1,121.3,8z M116.8,3.7v1.5h1.4c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H116.8z M119.3,7.8
                                                                    c0-0.5-0.3-0.8-0.8-0.8h-1.7v1.7h1.7C119,8.7,119.3,8.3,119.3,7.8z"/>
                                                            </g>
                                                            <g enableBackground="new">
                                                                <path fill="#575757" d="M123.4,7.7V1.8h2v5.7c0,0.7,0.3,1.2,1.4,1.2s1.4-0.6,1.4-1.2V1.8h2v5.9c0,1.9-1.4,3-3.3,3
                                                                    C124.8,10.7,123.4,9.6,123.4,7.7z"/>
                                                                <path fill="#575757" d="M134.7,1.8v8.7h-2V1.8H134.7z"/>
                                                            </g>
                                                            <g enableBackground="new">
                                                                <path fill="#575757" d="M142.5,8.6v1.9h-5.1V1.8h2v6.8H142.5z"/>
                                                            </g>
                                                            <g enableBackground="new">
                                                                <path fill="#575757" d="M149.5,3.8h-2.2v6.8h-2V3.8h-2.2V1.8h6.4V3.8z"/>
                                                            </g>
                                                            <g enableBackground="new">
                                                                <path fill="#575757" d="M162.4,8c0,1.5-1.2,2.5-2.8,2.5H156V1.8h3.4c1.5,0,2.7,1,2.7,2.5c0,0.7-0.3,1.3-0.8,1.7
                                                                    C162,6.4,162.4,7.1,162.4,8z M157.9,3.7v1.5h1.4c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H157.9z M160.4,7.8
                                                                    c0-0.5-0.3-0.8-0.8-0.8h-1.7v1.7h1.7C160.1,8.7,160.4,8.3,160.4,7.8z"/>
                                                            </g>
                                                            <g enableBackground="new">
                                                                <path fill="#575757" d="M168.3,7.2v3.3h-2V7.2l-2.9-5.3h2.3l1.6,3.3l1.6-3.3h2.3L168.3,7.2z"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>                              
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        );
    }
}

export default Footer;
