import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	withRouter,
} from 'react-router-dom';

// import Analytics from 'react-router-ga';
// import ReactGA from 'react-ga';

import { TransitionGroup, CSSTransition } from "react-transition-group";
import ScrollToTop from './utils/scrollToTop';

import HomePage 			from '../src/pages/home';
import AboutPage 			from '../src/pages/about';
import ServicesPage 		from '../src/pages/services';
import WhyMaltaPage 		from '../src/pages/why-malta';
import Contact 				from '../src/pages/contact';
// import Careers 				from '../src/pages/career/careers';
// import CareerSgl 			from '../src/pages/career/individual-career';
// import Events 				from '../src/pages/events/events';
// import News 				from '../src/pages/news/news';
// import NewsSgl 				from '../src/pages/news/single-news';
// import Meeting 				from '../src/pages/meeting';
// import AllCustomers 		from '../src/pages/customer-stories/all-customers';
// import ContactForm 			from './pages/contact-form';
// import CustomerStories 		from '../src/pages/customer-stories/customer-stories';
// import CustomerStoriesSgl 	from '../src/pages/customer-stories/single-customer-stories';
// import SolutionSingle 		from '../src/pages/solutionSingle';
// import ProductSingle 		from '../src/pages/productSingle';
// import IndustrySingle 		from '../src/pages/industrySingle';
// import Policy 				from './pages/policy';
// import NoMatch		 		from '../src/pages/404';
import "./assets/css/main.min.css";

let effect = "fade";
let time = 2000;

const MainApp = withRouter( props => {
	
	return (
		<div className="App">
				<TransitionGroup
					childFactory={child => React.cloneElement(
						child,
						{classNames: effect, timeout: time}
					)}
				>
					<CSSTransition 
						key={props.location.key}
						timeout={time}
					>
						<div className="transition-container">
							{/* <Navbar {...props} time={ time } location={ props.location.pathname } />; */}
							
							<Switch location={ props.location }>
								{/* Page Routing */}
								<Route exact path='/' 			component={ HomePage } />
								<Route exact path='/about' 		component={ AboutPage } />
								<Route exact path='/services' 	component={ ServicesPage } />
								<Route exact path='/why-malta' 	component={ WhyMaltaPage } />
								<Route exact path='/contact' 	component={ Contact } />
								{/* <Route exact path='/about' component={ About } />
								<Route exact path='/contact' component={ Contact } />
								<Route exact path='/try-it-free' component={ ContactForm } />
								<Route exact path='/resources/customer-stories/*' component={ props => <CustomerStoriesSgl {...props} /> } />
								<Route exact path='/careers/*' component={ props => <CareerSgl {...props} /> } />
								<Route exact path='/careers' component={ Careers } />
								<Route exact path='/resources/events' component={ Events } />
								<Route path='/resources/meeting' component={ Meeting } />
								<Route exact path='/resources/news' component={ News } />
								<Route exact path='/resources/news/*' component={ NewsSgl } />
								<Route exact path='/policies/*' component={ Policy } />
								<Route exact path='/resources/all-customers' component={ AllCustomers } />
								<Route exact path='/resources/customer-stories' component={ CustomerStories } />
								<Route exact path='/solutions/*' component= { props => <SolutionSingle {...props} /> } />
								<Route exact path='/products/*' component={ props => <ProductSingle {...props} /> } />
								<Route exact path='/industries/*' component={ props => <IndustrySingle {...props} /> } /> */}
								
								{/* <Route component={ NoMatch } /> */}
							</Switch>
						</div>
						
					</CSSTransition>
				</TransitionGroup>
		</div>

	)
});

class App extends Component {

	componentDidMount() {
		if (process.env.NODE_ENV === 'production') {
			// ReactGA.initialize('UA-70157542-1', { debug: false });
			// console.log("Track");
		} else {
			// console.log("Don't Track");
		}
	}

	render() {

		return (
			<Router>
				{/* <Analytics id="UA-70157542-1" debug trackPathnameOnly> */}
				<ScrollToTop>
					<MainApp />
				</ScrollToTop>
				{/* </Analytics> */}
			</Router>
		);
	}
}

export default App;

