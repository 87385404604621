import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NewsCard extends Component {

    render() {
        return (
            <Link
                to          = {"news/" + this.props.slug}
                className   = "news-card"
            >
                <div className="image-container grid-image">
                    <img src={this.props.image.url} alt={(this.props.image.alt) ? this.props.image.alt : this.props.title}/>
                </div>

                <div className="text-container">
                    <div className="post-meta">
                        <div className="category-list">
                            {
                                this.props.categories && this.props.categories.map( (category, key) => {
                                    return (
                                        <span key={key}>{category}</span>
                                    )
                                })
                            }
                        </div>
                        
                        <div className="post-date">
                            <span>{this.props.date}</span>
                        </div>
                    </div>
                    
                    <h3 className="post-title">{this.props.title}</h3>
                    
                    <div className="read-more-link">
                        <span>Read More</span>

                        <div className="arrow-cont right-arrow">
                            <svg viewBox="0 0 10.212 10.213">
                                <defs>
                                    <clipPath id="clip-path">
                                    <rect id="Rectangle_1048" data-name="Rectangle 1048" width="10.213" height="10.213" fill="#fff" stroke="#707070" strokeWidth="1"/>
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_25" data-name="Mask Group 25" transform="translate(10.212 10.213) rotate(180)" clipPath="url(#clip-path)">
                                    <path id="launch-arrow" d="M7.427,0l2.785,2.321L7.427,4.642V2.785H0V1.857H7.427Z" transform="translate(0 2.785)" fill="#fff"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default NewsCard;