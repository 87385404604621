import React, { Component } from 'react';

import {
    Link
} from 'react-router-dom';

const FormButton = (props) => {
    return(
        <div className={'button ' + props.classes} onClick={props.onClick}>
            <div className="inner-button form-button">
                <span>{props.text}</span>
            </div>
        </div>
    )
}

const DefaultButton = (props) => {
    return(
        <div className={'button ' + props.classes}>
            <Link
                target = {props.target}
                to = {{
                    pathname: props.link,
                    state: { 
                        transition: (props.transition) ? props.transition : "fade",
                        // timeout: (props.timeout) ? props.timeout : 2250
                        timeout: (props.timeout) ? props.timeout : 0
                    }
                }}            
            >
                <div className="inner-button">
                    <span>{props.text}</span>
                </div>
            </Link>
        </div>
    )
}

class Button extends Component {
    render() {
        var button;
    
        if ( this.props.position === "form" ) {
            button = FormButton(this.props);
        } else {
            button = DefaultButton(this.props);
        }
    
        return (
            button   
        );
    }
}

export default Button;
