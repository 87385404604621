import React, { Component }     from 'react';
import { Link }                 from 'react-router-dom';
import footerImg                from '../../../assets/images/footer-cta.jpg';
import mailLogo                 from '../../../assets/images/mail.svg';
import servicesLogo             from '../../../assets/images/services.svg';
import Title                    from '../typography/titleSection';
import Animate from '../../animation/animate';

class FooterCta extends Component {
    render(){
        return(
            <Animate classes="image-banner-anim">
                <section className={"image-banner-section "}>
                    <div className={"image-container "}>
                        <img src={footerImg} alt={"Contact Us"} />
                    </div>

                    <div className="container">
                        <div className="inset-container">
                            <Title
                                text        = {"Interested in Learning More?"}
                                titleType   = "h2"
                                classes     = {"white-color"}
                            />

                            <div className="button-cont mt-md">
                                <div className="res-col space-col white-text bold">
                                    <div className="col">
                                        <div className="cta-section">
                                            <div className="res-col">
                                                <div className="col">
                                                    <img className="icon-helper" src={mailLogo} alt="Mail"/>
                                                </div>
                                                <div className="col">
                                                    <Link to="contact">Contact Us</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="cta-section">
                                            <div className="res-col">
                                                <div className="col">
                                                    <img className="icon-helper" src={servicesLogo} alt="Services"/>
                                                </div>
                                                
                                                <div className="col">
                                                    <Link to="services">Our Services</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section>
            </Animate>
        )
    }
}

export default FooterCta;