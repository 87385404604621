import React, { Component } from 'react';

import ContactForm          from '../forms/contactForms';
import DataApi from '../../../utils/data-api';

class ContactDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }

    componentDidMount() {
        var fetchContactData = DataApi.getOptions();

        Promise.resolve(fetchContactData).then( response => {
            this.setState({
                contact:   response.acf,
            })
        }).then( () => {
            this.setState({
                loaded: true,
            })
        });
    }

    render() {
        return (
            <div className="container">
                <div className="row align-center screen-height contact-main-row">
                    <div className="col-2">
                        {(this.state.loaded) ?
                            <div>                                
                                <h2>Got a project?<br></br><span className="light">Let's Talk...</span></h2>

                                <div className="contact-details">
                                    <div className="row">
                                        <div className="col-1">
                                            <div className="secondary-text">
                                                <span>address</span>
                                            </div>

                                            <p>{this.state.contact.address}</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-1">
                                            <div className="secondary-text">
                                                <span>number</span>
                                            </div>

                                            <p><a href={"tel:" + this.state.contact.number}>{this.state.contact.number}</a></p>
                                        </div>
                                        
                                        <div className="col-1">
                                            <div className="secondary-text">
                                                <span>email</span>
                                            </div>

                                            <p><a href={"mailto:" + this.state.contact.email}>{this.state.contact.email}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : ""}
                    </div>

                    <div className="col-3">
                        <ContactForm />
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactDetails;