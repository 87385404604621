import React, { Component } from 'react';
import Slide                from './slide';
import { Lethargy }         from 'lethargy';
// import DataApi              from '../../../utils/data-api';
import { Swipeable }           from 'react-swipeable';

import bgImg    from '../../../assets/images/canna-bg.jpg';
import homeVid    from '../../../assets/images/home-video.mp4';

const slideAmount   = 5;    // amount of slides
const waitTime      = 800; // wait time in between scrolls
const lethargy      = new Lethargy(7, 100, 0.05);


class SliderMaster extends Component {

    constructor(props) {
        super(props);

        this.setSlide = this.setSlide.bind(this);
        this.swiped = this.swiped.bind(this);
        
        this.state = {
            isMoving:       false,
            slideIndex:     0
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.setSlide);
        document.body.style.overflow            = "hidden";      
        document.body.style.overscrollBehavior  = "none";
        document.body.style.position            = "fixed";      
        document.body.style.width               = "100%";
 
        // var fetchPostsData = this.props.page.acf.select_brands.map( (brand) => {
        //     return DataApi.getPost( 'brand', brand.post_name )
        // })

        // Promise.all( fetchPostsData ).then((responses) => {

        //     var brands = responses.map( (brand) => {
        //         return brand[0]
        //     })
        //     this.setState({
        //         brands:      brands
        //     });
        // });
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.setSlide);
        document.body.style.overflow            = "auto";
        document.body.style.overflowX           = "hidden";
        document.body.style.position            = "relative";      
        document.body.style.overscrollBehavior  = "auto";   
        // document.body.style.width       = "100%";    
    }

    scrollDetect( scrollData ) {
        // console.log(this.state.lethargy.check(scrollData));

        if (lethargy.check(scrollData)) {
            if ( !this.state.isMoving ) {
                let scrollForce = scrollData.deltaY;
                let adjIndex    = this.state.slideIndex;
    
                if ( scrollForce > 0 && this.state.slideIndex < (slideAmount-1) ) {
                    // scroll Down
                    adjIndex = this.state.slideIndex + 1;
                } else if ( scrollForce < 0 && this.state.slideIndex > 0 ) {
                    // scroll Up
                    adjIndex = this.state.slideIndex - 1;
                }
    
                this.setState({
                    slideIndex: adjIndex,
                }, () => {
                    this.slideCounter();
                })
            }
        } else {
            // console.log(lethargy.check(scrollData));
        }
    }

    goToSlide(index) {
        this.setState({
            slideIndex: index,
        }, () => {
            this.slideCounter();
        })
    }

    slideCounter() {

        this.setState({
            isMoving: true
        })

        this.setSlide();

        setTimeout( function() {
            this.setState({
                isMoving: false
            });
        }.bind(this), waitTime);

    }

    setSlide() {

        let windowHeight    = document.body.clientHeight;
        let topMove         = windowHeight * (this.state.slideIndex);

        this.innerSlider.style.transform = "translateY(" + -topMove + "px)";

        if ( this.state.slideIndex === 1 ) {
            // document.getElementById("mainNavigation").classList.add("accent-menu");
        } else {
            // document.getElementById("mainNavigation").classList.remove("accent-menu");
        }
    }

    createNav = () => {
        let navItems = []
    
        // Outer loop to create parent
        for (let i = 0; i < slideAmount; i++) {
            navItems.push(<div className={"slider-button " + ((this.state.slideIndex === i) ? "active" : "")} onClick={() => this.goToSlide(i)} key={i}><span></span></div>)
        }

        return navItems;
    }

    swiped(e, deltaY) {
        this.scrollDetect(e);
    }

    render() {
        // Get slides from slide component.
        // All logic for slider sits here

        return (
            <Swipeable className="swipe-elem" onSwipedUp={ this.swiped } onSwipedDown={ this.swiped }>
                <div className="slide-container" onWheel = {(e) => this.scrollDetect(e)}>
                    <div className="slider-nav">
                        <div className="slider-button-cont">
                            {this.createNav()}
                        </div>
                    </div>

                    <div className="inner-slide-container" ref={slider => this.innerSlider = slider}>

                        <Slide
                            name        = "video"
                            url         = {homeVid}
                            poster_url  = {bgImg}
                            indexId     = {0}
                            index       = {this.state.slideIndex}
                            text        = {"<h1>A European Consultancy firm specialising in the global cannabis industry</h1>"}
                            textSize    = {"xl"}
                            vAlign      = {"m"}
                            hAlign      = {"left"}
                            // numAlign    = {this.props.page.acf.first_slide.number_alignment}
                            addButton   = { true }
                            // classes = {((this.state.slideIndex === 0) ? "current-slide " : "")}
                        />

                        <Slide
                            name        = "text"
                            // url         = {this.props.page.acf.first_slide_video.url}
                            indexId     = {1}
                            index       = {this.state.slideIndex}
                            secondary   = {"About The Company"}
                            text        = {"<h2>Cannanalytica is a Malta, London and Portugal based consultancy firm specialising in the Global medical cannabis industry.</h2>"}
                            textSize    = {"xl"}
                            vAlign      = {"m"}
                            // hAlign      = {this.props.page.acf.first_slide.horizontal_alignment}
                            // numAlign    = {this.props.page.acf.first_slide.number_alignment}
                            addButton   = { true }
                            // classes = {((this.state.slideIndex === 0) ? "current-slide " : "")}
                        />

                        <Slide
                            name    = "brand"
                            indexId = {2}
                            index   = {this.state.slideIndex}
                            // brands  = {this.state.brands}
                            // text    = {this.props.page.acf.second_slide_text}
                            // classes = {((this.state.slideIndex === 1) ? "current-slide " : "")}
                        />
                        
                        <Slide
                            name    = "why"
                            indexId = {3}
                            index   = {this.state.slideIndex}
                            // brands  = {this.state.brands}
                            // text    = {this.props.page.acf.second_slide_text}
                            // classes = {((this.state.slideIndex === 1) ? "current-slide " : "")}
                        />

                        <Slide
                            name    = "service"
                            indexId = {4}
                            index   = {this.state.slideIndex}
                            // classes = {((this.state.slideIndex === 2) ? "current-slide " : "")}
                        />
                        
                        {/* <Slide
                            name    = "service"
                            indexId = {3}
                            index   = {this.state.slideIndex}
                            data    = {this.props.page.acf.home_services}
                            // classes = {((this.state.slideIndex === 2) ? "current-slide " : "")}
                        /> */}
                        
                        {/* <Slide
                            name    = "news"
                            indexId = {4}
                            news    = {this.props.news}
                            index   = {this.state.slideIndex}
                            // classes = {((this.state.slideIndex === 4) ? "current-slide " : "")}
                        />
                        
                        <Slide
                            name    = "contact"
                            indexId = {5}
                            index   = {this.state.slideIndex}
                            // classes = {((this.state.slideIndex === 5) ? "current-slide " : "")}
                        /> */}
                    </div>
                </div>
            </Swipeable>
        );
    }
}

export default SliderMaster;
