import React, { Component } from 'react';

class SecondaryText extends Component {
    render() {
        return (
            <div className={"secondary-text " + ((this.props.white) ? "white-color" : "")}>
                <span>{this.props.text}</span>
            </div>
        );
    }
}

export default SecondaryText;
