import React, { Component } from 'react';
import {
    Link,
    withRouter
} from 'react-router-dom';

import { withCookies }  from 'react-cookie';
import DataApi          from '../../utils/data-api';
import Services         from '../../assets/images/licenses.jpg';
import About            from '../../assets/images/vision.jpg';
import WhyMalta         from '../../assets/images/malta.jpg';
import Contact          from '../../assets/images/footer-cta.jpg';
import MouseFollow      from '../mouseFollow/mouseFollow';

var positions           = [];
var whitePositions      = [];

class Header extends Component {

    constructor(props) {
        super(props);

        this.toggleMenu     = this.toggleMenu.bind(this);
        this.removeMenu     = this.removeMenu.bind(this);
        this.setPositions   = this.setPositions.bind(this);
        this.logoScroll     = this.logoScroll.bind(this);
        this.cookieHandler  = this.cookieHandler.bind(this);

        const { cookies } = props;
        
        this.state = {
            loadingVisible: cookies.get('loadingVisible') || false,
            cookieBar: cookies.get('cookieBar') || false,
            active: false,
            logoAccent : false,
            menuWhite : true,
            compact : false,
            loaded: false,
            selectedItem: null
        };
    }

    componentDidMount() {
        if ( !this.state.loadingVisible ) {
            this.props.cookies.set('loadingVisible', true, { path: '/' });
            console.log('%c This site has been crafted by Bison Studio | bison.studio ', 'background: black; color: #FF4545; padding: 30px 20px');
        }

        var fetchContactData = DataApi.getOptions();

        Promise.resolve(fetchContactData).then( response => {
            this.setState({
                contact:   response.acf,
            })
        }).then( () => {
            this.setState({
                loaded: true,
            })
        });

        setTimeout( this.logoScroll, 2750 );
        // alert("mounted");
        window.addEventListener('scroll', this.logoScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.logoScroll);
    }

    cookieHandler() {
        if ( !this.state.cookieBar ) {
            this.props.cookies.set('cookieBar', true, { path: '/' });

            this.setState({
                cookieBar: true
            })
        }
    }

    setPositions() {
        // alert("test");

        var lightElems  = document.getElementsByClassName("accent-section");
        positions       = [];
        var elemPos;
        var startingPos;
        var height;
        var objPos;
        
        for (let element of lightElems) {
            elemPos     = element.getBoundingClientRect();
            startingPos = elemPos.top;
            height      = elemPos.height;
            objPos      = {startingPos: startingPos, height: height, element: element};
            
            positions.push(objPos);
        }

        var whiteElems  = document.getElementsByClassName("white-section");
        whitePositions  = [];
        
        for (let element of whiteElems) {
            elemPos     = element.getBoundingClientRect();
            startingPos = elemPos.top;
            height      = elemPos.height;
            objPos      = {startingPos: startingPos, height: height, element: element};
            
            whitePositions.push(objPos);
        }
    }
    
    logoScroll(event) {
        // alert("logoSc");
        this.setPositions();

        let scrollPos = window.scrollY;
        let positionStates  = [];
        let whitePositionStates  = [];

        var scrollArrs = document.getElementsByClassName("scroll-container");

        // console.log(scrollArrs);

        if ( scrollPos > 50 ) {
            // Compact

            for (let element of scrollArrs) {
                // console.log(element);
                // scroll
                element.style.opacity = "0";
            }

        }

        if ( scrollPos === 0 ) {
            for (let element of scrollArrs) {
                // console.log(element);
                // scroll
                element.style.opacity = "1";
            }
        }

        //     // Not Compact

        //     this.setState({
        //         compact : false
        //     })

        // }

        for (let elementTest of positions) {

            if ( elementTest.startingPos < 100 && (-elementTest.startingPos) < elementTest.height-100 ) {
                positionStates.push(true);
            } else {
                positionStates.push(false);
            }
        }

        for (let elementTest of whitePositions) {

            if ( elementTest.startingPos < 100 && (-elementTest.startingPos) < elementTest.height-100 ) {
                whitePositionStates.push(true);
            } else {
                whitePositionStates.push(false);
            }
        }
        
        if ( whitePositionStates.includes(true) ) {
            // Accent Logo
            this.setState({
                menuWhite : false
            })
            
        } else {
            //  Not Accent Logo
            this.setState({
                menuWhite : true
            })
        }

        // change nav on scrolling over the service cards
        let navbar = document.querySelector(".inner-header");
        let serviceCard = document.querySelector(".alt-service-container");

        if(serviceCard){
            if(serviceCard.offsetTop < window.pageYOffset){
                navbar.classList.remove("accent-menu");
            } else {
                navbar.classList.add("accent-menu");
            }
        }
    }

    removeMenu() {
        this.setState({ active: false });
    }

    toggleMenu() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });        
    }

    render() {
        return (
            <div>
                <MouseFollow/>
                {/* <div className={"cookie-bar " + ((this.state.cookieBar) ? "disabled" : "")}>
                    <div className="inner-cookie-bar">
                        <div className="container">
                            <div className="row align-center">
                                <div className="col-3">
                                    <p>This website uses cookies to ensure you get the best experience on our website. By using our site you agree to our use of cookies and to our privacy policy.</p>
                                </div>

                                <div className="col-2">
                                    <div className="res-col">
                                        <div className="col-1 first-button">
                                            <Button
                                                classes = 'white-color text-button'
                                                link    = '/privacy-policy'
                                                text    = 'Learn More'
                                                target  = "_blank"
                                            />
                                        </div>

                                        <div className="col-1">
                                            <div className="button white-color" onClick={this.cookieHandler}>
                                                <div className="inner-button form-button">
                                                    <span>OK</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <header className={"site-header " + ((this.props.lightMode) ? "light-mode" : '')}>
                    <div className="extend-container">
                        <div id="mainNavigation" className={"inner-header res-col " + ((this.state.menuWhite || this.state.active ) ? "" : " accent-menu")}>
                            <div className={ "main-logo" + ((this.state.compact ) ? " compact" : "") }>
                                <Link onClick={this.removeMenu} to="/">
                                    <svg width="145.419" height="20.5" viewBox="0 0 145.419 20.5">
                                    <g id="logo-text" data-name="Group 1844" transform="translate(-255.426 -465.522)">
                                        <path id="Path_2" data-name="Path 2" d="M255.426,483.435v-5.909c0-2.38.268-3.823,1.1-4.866a5.552,5.552,0,0,1,7.807,0,4.541,4.541,0,0,1,.856,2.005,18.068,18.068,0,0,1,.241,3.422h-3.449a22.633,22.633,0,0,0-.08-2.406,1.95,1.95,0,0,0-.294-.963,1.513,1.513,0,0,0-2.353,0c-.294.428-.374.909-.374,2.193v7.166a3.573,3.573,0,0,0,.374,2.166,1.513,1.513,0,0,0,2.353,0,1.95,1.95,0,0,0,.294-.963,23.169,23.169,0,0,0,.08-2.38h3.449a18.356,18.356,0,0,1-.241,3.449,4.952,4.952,0,0,1-.856,1.978,5.619,5.619,0,0,1-7.807,0C255.693,487.285,255.426,485.815,255.426,483.435Z" transform="translate(0 -3.889)" fill="#fff"/>
                                        <path id="Path_3" data-name="Path 3" d="M300.551,486.3h-3.182l-.375,3.9h-3.529q1.084-8.662,2.755-18.422h5.454q1.484,8.3,2.807,18.422h-3.556Zm-.321-2.888-.241-1.925c-.187-1.658-.481-3.823-.936-6.5h-.294c-.455,3.182-.722,5.348-.829,6.524l-.241,1.9Z" transform="translate(-26.73 -4.395)" fill="#fff"/>
                                        <path id="Path_4" data-name="Path 4" d="M337.083,471.778h2.941l2.593,6.818c.615,1.578,1.1,2.941,1.47,4.118h.08c-.054-1.471-.08-3.021-.08-4.626v-6.31h3.155V490.2h-2.861l-2.673-6.979c-.508-1.31-1.016-2.674-1.47-4.117h-.08c.027.989.054,2.514.054,4.626V490.2h-3.128Z" transform="translate(-57.38 -4.396)" fill="#fff"/>
                                        <path id="Path_5" data-name="Path 5" d="M380.069,471.78h2.941L385.6,478.6c.615,1.577,1.1,2.941,1.47,4.117h.08c-.054-1.471-.08-3.021-.08-4.626v-6.31h3.155V490.2h-2.861l-2.674-6.979c-.508-1.31-1.016-2.674-1.47-4.117h-.081c.027.989.054,2.513.053,4.626V490.2h-3.128Z" transform="translate(-87.587 -4.398)" fill="#fff"/>
                                        <path id="Path_6" data-name="Path 6" d="M429.538,485.844h-5.294c-.455,2.112-.8,3.743-1.016,4.866H421.7c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208h-1.551Zm-.267-1.284-.856-4.01-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-116.845 -4.905)" fill="#fff"/>
                                        <path id="Path_7" data-name="Path 7" d="M466.129,472.5h1.364l4.2,9.946c.989,2.38,1.684,4.091,2.058,5.16h.053c-.08-2.139-.107-3.957-.107-5.481V472.5h1.417v18.208h-1.364l-4.3-10.214c-.855-2.032-1.5-3.663-1.951-4.92h-.08c.08,1.417.107,3.262.107,5.481v9.652h-1.39Z" transform="translate(-148.062 -4.906)" fill="#fff"/>
                                        <path id="Path_8" data-name="Path 8" d="M513.711,485.848h-5.294c-.455,2.112-.8,3.743-1.016,4.866h-1.524c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208H514.7Zm-.268-1.283-.855-4.011-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-175.994 -4.908)" fill="#fff"/>
                                        <path id="Path_9" data-name="Path 9" d="M550.3,472.507h1.5v16.9h5.508v1.31H550.3Z" transform="translate(-207.211 -4.908)" fill="#fff"/>
                                        <path id="Path_10" data-name="Path 10" d="M574.63,484c-1.31-3.663-2.593-7.486-3.823-11.5h1.577c.829,2.941,1.818,6.177,2.994,9.706h.08c1.043-3.289,2.006-6.524,2.915-9.705H579.9c-1.283,4.358-2.54,8.181-3.77,11.523v6.685h-1.5Z" transform="translate(-221.621 -4.909)" fill="#fff"/>
                                        <path id="Path_11" data-name="Path 11" d="M609.623,473.846h-3.476v-1.337h8.422v1.337H611.12v16.871h-1.5Z" transform="translate(-246.455 -4.91)" fill="#fff"/>
                                        <path id="Path_12" data-name="Path 12" d="M643.466,472.511h1.5v18.208h-1.5Z" transform="translate(-272.679 -4.911)" fill="#fff"/>
                                        <path id="Path_13" data-name="Path 13" d="M660.462,484.225V478c0-2.38.24-3.77,1.016-4.759a4.559,4.559,0,0,1,6.657,0c.776.963,1.043,2.246,1.043,4.465h-1.5c0-1.845-.187-2.807-.749-3.556a2.778,2.778,0,0,0-4.251,0c-.535.722-.722,1.738-.722,3.877V484.2c0,2.139.187,3.155.722,3.877a2.465,2.465,0,0,0,2.112.963,2.515,2.515,0,0,0,2.139-.963,5.862,5.862,0,0,0,.749-3.556h1.5c0,2.246-.321,3.529-1.043,4.465a4.558,4.558,0,0,1-6.657,0C660.7,488,660.462,486.6,660.462,484.225Z" transform="translate(-284.623 -4.407)" fill="#fff"/>
                                        <path id="Path_14" data-name="Path 14" d="M704.538,485.856h-5.294c-.455,2.112-.8,3.743-1.016,4.866H696.7c1.257-6.123,2.594-12.192,4.065-18.208h2.246q2.246,9.465,4.063,18.208h-1.551Zm-.267-1.283-.856-4.011-1.47-6.6h-.134l-1.043,4.652-.428,1.952-.829,4.01Z" transform="translate(-310.09 -4.913)" fill="#fff"/>
                                        <path id="Path_15" data-name="Path 15" d="M736.531,468.156a1.7,1.7,0,1,1-3.4,0v-.851a1.7,1.7,0,1,1,3.4,0Zm-.311-.825c0-.984-.476-1.53-1.4-1.53s-1.384.54-1.384,1.53v.8c0,.99.463,1.53,1.384,1.53s1.4-.546,1.4-1.53Zm-.743,1.593h-.311c-.032-.1-.089-.222-.14-.368-.063-.178-.139-.368-.222-.578h-.254v.946h-.3v-2.412h.559c.451,0,.654.178.654.66v.153c0,.374-.121.552-.381.622Zm-.927-1.2h.273c.26,0,.343-.127.343-.413v-.121c0-.3-.082-.419-.343-.419h-.273Z" transform="translate(-335.686)" fill="#fff"/>
                                    </g>
                                    </svg>
                                </Link>
                            </div>     

                            <div className={"menu-icon " + (this.state.active ? "menu-close" : "")}  onClick={this.toggleMenu}>
                                <div className="inner-icon">
                                    <div id="top" className="bar"></div>
                                    <div id="middle" className="bar"></div>
                                    <div id="bottom" className="bar"></div>
                                </div>

                                <div className="menu-text">
                                    <span></span>
                                </div>
                            </div>
                        </div>

                        <nav className={(this.state.active ? "active" : "") + " main-navigation"}>
                            <div className="inner-navigation">
                            
                                <div className="menu-image-container">
                                    <div className="inner-container">
                                        <div className={"image-item " + ((this.state.selectedItem === 0) ? "active" : "")}>
                                            <img src={About} alt="About"/>
                                        </div>
                                        <div className={"image-item " + ((this.state.selectedItem === 1) ? "active" : "")}>
                                            <img src={Services} alt="Services"/>
                                        </div>
                                        <div className={"image-item " + ((this.state.selectedItem === 2) ? "active" : "")}>
                                            <img src={WhyMalta} alt="Why Malta"/>
                                        </div>
                                        <div className={"image-item " + ((this.state.selectedItem === 3) ? "active" : "")}>
                                            <img src={Contact} alt="Contact"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="inset-container">
                                    <ul className="extend-container">
                                        <li onMouseOver={() => ( window.innerWidth > 600) ? this.setState({selectedItem: 0}) : ""} >
                                            <Link className={ (this.props.location.pathname === "/about-us") ? "active-page" : "" } onClick={this.toggleMenu} to="/about">About</Link>
                                        </li>

                                        <li onMouseOver={() => ( window.innerWidth > 600) ? this.setState({selectedItem: 1}) : ""} >
                                            <Link className={ (this.props.location.pathname === "/services") ? "active-page" : "" } onClick={this.toggleMenu} to="/services">Services</Link>
                                        </li>
                                        
                                        {/* <li onMouseOver={() => ( window.innerWidth > 600) ? this.setState({selectedItem: 2}) : ""} >
                                            <Link className={ (this.props.location.pathname === "/why-malta") ? "active-page" : "" } onClick={this.toggleMenu} to="/why-malta">Why Malta</Link>
                                        </li> */}

                                        <li onMouseOver={() => ( window.innerWidth > 600) ? this.setState({selectedItem: 3}) : ""} >
                                            <Link className={ (this.props.location.pathname === "/contact") ? "active-page" : "" } onClick={this.toggleMenu} to="/contact">Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                        </nav>
                    
                    </div>

                    
                    {/* </ ReactCSSTransitionGroup> */}

                    {/* <LoadingScreen
                        loadingState = {this.state.loadingVisible}
                    /> */}
                    {/* <div className={"social-icons-cont "  + ((this.state.menuWhite ) ? "" : " accent-menu")}>
                        {(this.state.loaded) ?
                            <div className="social-icons col">
                                <div id="facebook" className="social-icon">
                                    <a target="_blank" rel="noopener noreferrer" href="https://facebook.com">
                                        <div className="inner-social-icon">
                                            <svg viewBox="0 0 96.227 96.227" >
                                            <g>
                                                <path fill="white" d="M73.099,15.973l-9.058,0.004c-7.102,0-8.477,3.375-8.477,8.328v10.921h16.938l-0.006,17.106H55.564v43.895H37.897V52.332
                                                    h-14.77V35.226h14.77V22.612C37.897,7.972,46.84,0,59.9,0L73.1,0.021L73.099,15.973L73.099,15.973z"></path>
                                            </g>
                                            </svg>
                                        </div>
                                    </a>                        
                                </div>

                                <div id="instagram" className="social-icon">
                                    <a target="_blank" rel="noopener noreferrer" href="#">
                                        <div className="inner-social-icon">
                                            <svg viewBox="0 0 169.063 169.063">
                                            <g>
                                                <path fill="white" d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
                                                    c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
                                                    c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
                                                    c17.455,0,31.656,14.201,31.656,31.655V122.407z"></path>
                                                <path fill="white" d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
                                                    C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
                                                    c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"></path>
                                                <path fill="white" d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
                                                    c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
                                                    C135.661,29.421,132.821,28.251,129.921,28.251z"></path>
                                            </g>
                                            </svg>
                                        </div>
                                    </a>
                                </div>

                                <div id="linkedin" className="social-icon">
                                    <a target="_blank" rel="noopener noreferrer" href="#">
                                        <div className="inner-social-icon">
                                            <svg viewBox="0 0 430.117 430.117">
                                            <g>
                                                <path fill="white" id="LinkedIn" d="M430.117,261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707
                                                    c-25.473,0-40.632,17.142-47.301,33.724c-2.432,5.928-3.058,14.179-3.058,22.477V420.56h-92.219c0,0,1.242-251.285,0-277.32h92.21
                                                    v39.309c-0.187,0.294-0.43,0.611-0.606,0.896h0.606v-0.896c12.251-18.869,34.13-45.824,83.102-45.824
                                                    C384.633,136.724,430.117,176.361,430.117,261.543z M52.183,9.558C20.635,9.558,0,30.251,0,57.463
                                                    c0,26.619,20.038,47.94,50.959,47.94h0.616c32.159,0,52.159-21.317,52.159-47.94C103.128,30.251,83.734,9.558,52.183,9.558z
                                                    M5.477,420.56h92.184v-277.32H5.477V420.56z"/>
                                            </g>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        : ""}
                    </div> */}
                </header>
            
            </div>
        );
    }
}

export default withCookies(withRouter(Header));
