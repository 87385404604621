import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class WorkSliderCard extends Component {

    render() {
        return (
            <div className={"work-slider-card " + ((this.props.active) ? "active" : "")}>
                <Link
                    // className   = {"work-slider-card " + ((this.props.active) ? "active" : "")}
                    to          = {"/our-work/" + this.props.slug}
                >
                    <div>
                        <div className="image-cont">
                            <div className="image-wrap">
                                <div className="image-container bg-img">
                                    <img src={this.props.image.url} alt={(this.props.image.alt) ? this.props.image.alt : this.props.title}/>
                                </div>
                            </div>
                        </div>

                        <div className="text-container">
                            <h3>{this.props.title}</h3>

                            <div className="service-list">
                                {this.props.services && this.props.services.map( (service, key) => {
                                    return (
                                        <span className="secondary-text" key={key}>{service}</span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default WorkSliderCard;