import React, { Component } from 'react';
import Header 			    from '../components/header/header';
import HeroPageShift        from '../components/sections/page/heroPageShift';
// import DataApi              from '../utils/data-api';
import SliderMaster         from '../components/sections/slider/sliderMaster';
import LoadingScreen        from '../components/loading/loading';
// import WpSeo                from '../utils/wp-seo';

class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded:         true,
        };
    }
    
    componentDidMount() {
        // var fetchNewsData   = DataApi.getPosts( 'posts', 5 );
        // var fetchPageData   = DataApi.getPost( 'pages', 'home' );
        
        // Promise.all([ fetchPageData, fetchNewsData ]).then((response) => {
        //     this.setState({
        //         page:   response[0][0],
        //         news:   response[1]
        //     });
        // }).then( () => {

        //     this.setState({
        //         loaded:     true,
        //     })
        // });
    }

    render() {
        // if ( this.state.loaded ) {
        return (
            <div className="page-container fixed-hero">
                <LoadingScreen
                    loadingState = {this.state.loaded}
                />

                {(this.state.loaded) ?

                    <div>
                        {/* <WpSeo
                            slug        = "home"
                            pageHeaders = {true}
                            pageObject  = {this.state.page.yoast_meta}
                        /> */}

                        <Header />
    
                        <HeroPageShift
                            fullHeight  = {true}
                            classes     = "fixed"
                        />
    
                        <SliderMaster
                            // news    = {this.state.news}
                            // page    = {this.state.page}
                        />
    
                        {/* <Footer /> */}
                    </div>
                : ""}
            </div>     
        );
    }
}

export default HomePage;