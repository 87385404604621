// const urlPre        = 'https://studioseven.com.mt/wp-json/wp/v2/';
// const acfUrl        = "https://studioseven.com.mt/wp-json/acf/v3/options/option";

const mainUrl       = 'https://cannanalytica.com';
// const mainUrl       = 'http://localhost:8888/studio-seven-backend';
const urlPre        = mainUrl + '/wp-json/wp/v2/';
const acfUrl        = mainUrl + '/wp-json/acf/v3/options/option';

class DataApi {
    static cacheLocal( key, payload ) {
        sessionStorage.setItem(key, payload);
    }

    static getCache( key ) {
        var payload = ( sessionStorage.getItem(key) ) ? sessionStorage.getItem(key) : false;
        
        return payload;
    }

    static getOptions() {
        var fetchPosts          = this.callApi(acfUrl);

        return Promise.resolve(fetchPosts);
    }

    static getPosts( postType, amount, page = 1 ) {
        var postsPage           = (amount === -1) ? "?per_page=100" : "?per_page=" + amount + "&page=" + page ; 
        var apiUrl              = urlPre + postType + postsPage;
        var fetchPosts          = this.callApi(apiUrl);

        return Promise.resolve(fetchPosts);
    }

    static getPost( postType, slug ) {
        var apiUrl              = urlPre + postType + "?slug=" + slug;
        var fetchPosts          = this.callApi(apiUrl);

        return Promise.resolve(fetchPosts);
    }

    static getNextPost( postType, slug ) {
        var apiUrl          = urlPre + "next-post?type=" + postType + "&slug=" + slug;
        var fetchPosts      = this.callApi(apiUrl);

        return Promise.resolve(fetchPosts);
    }

    static getEndpoint( url ) {
        var apiUrl          = urlPre + url;
        var fetchPosts      = this.callApi(apiUrl);

        return Promise.resolve(fetchPosts);
    }  
    
    static postEndpoint( url, options ) {
        return fetch(urlPre + url, options)
            .then(response => response.json())
            .then(response => {
                return response;
            }).catch(error => {
                return error;
            });
    }    

    static callApi( apiUrl ) {

        if (process.env.NODE_ENV === 'production') {
            var cachedResponse      = this.getCache( apiUrl );
    
            if ( cachedResponse ) {
                // console.log("cached");
    
                return JSON.parse(cachedResponse);
            } else {
                // console.log("RE-CACHED");
    
                return fetch(apiUrl)
                    .then(response => response.json())
                    .then(response => {
                        this.cacheLocal(apiUrl, JSON.stringify(response));
                        return response;
                    }).catch(error => {
                        return error;
                    });
            }
        } else {
            // console.log("RE-CACHED");

            return fetch(apiUrl)
                .then(response => response.json())
                .then(response => {
                    this.cacheLocal(apiUrl, JSON.stringify(response));
                    return response;
                }).catch(error => {
                    return error;
                });
        }
    }
}

export default DataApi;  