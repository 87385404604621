import React, { Component } from 'react';
import Slider               from "react-slick";
import Draggable            from 'react-draggable';

import WorkSliderCard       from './work-slider-card';
import Title                from '../../sections/typography/titleSection';
import DataApi              from '../../../utils/data-api';

class WorkSlider extends Component {

    constructor(props) {
        super(props);

        this.afterChangeHandler     = this.afterChangeHandler.bind(this);
        this.beforeChangeHandler    = this.beforeChangeHandler.bind(this);
        this.navSlider              = this.navSlider.bind(this);
        this.handleDrag             = this.handleDrag.bind(this);
        this.stopDrag               = this.stopDrag.bind(this);
        
        this.state = {
            currentSlide:   0,
            numberSlide:    0,
            slider:         null,
            speed:          900,
            dragging:       false
        };
    }

    componentDidMount() {
        var fetchPostData = DataApi.getPosts( 'work', 5 );

        Promise.all([ fetchPostData ]).then((responses) => {
            this.setState({
                posts:          responses[0],
            });
        }).then( () => {
            this.setState({
                loaded: true,
            })
        });
    }

    afterChangeHandler(currentSlide) {    
        this.setState({
            currentSlide:   currentSlide,
            numberSlide:    currentSlide
        })
    }

    beforeChangeHandler(prevSlide, currentSlide) {
        if ( !this.state.dragging ) {
            this.setDrag(currentSlide);

            this.setState({
                numberSlide:    currentSlide,
            })
        }
    }

    navSlider( direction ) {
        // console.log(direction);
        if ( direction === "back" ) {
            this.slider.slickGoTo(this.state.currentSlide - 1);
        } else {
            this.slider.slickGoTo(this.state.currentSlide + 1);
        }
    }

    handleDrag() {
        var obj         = document.getElementById("scroll-item");
        var childPos    = obj.getBoundingClientRect();
        var parentPos   = obj.parentElement.getBoundingClientRect();
        var childOffset = (((childPos.x - parentPos.x) + childPos.width) / parentPos.width);
        var slideIndex  = Math.round(childOffset * this.state.posts.length) - 1;

        this.setState({
            sliderPos:  slideIndex,
            speed:      100,
            dragging:   true
        })

        this.slider.slickGoTo(slideIndex);

        // console.log(this.slider);
    }

    setDrag(index) {
        var obj         = document.getElementById("scroll-item");
        var childPos    = obj.getBoundingClientRect();
        var parentPos   = obj.parentElement.getBoundingClientRect();
        var ratioScroll = ((parentPos.width - childPos.width) / (this.state.posts.length-1));
        var leftPos     = ratioScroll * index;


        obj.style.transform = "translate(" + leftPos + "px,0)"
    }

    stopDrag() {
        this.setState({
            speed:      900,
            dragging:   false
        })
    }

    render() {
        var settings = {
            speed: this.state.speed,
            variableWidth: true,
            centerMode: false,
            infinite: false,
            arrows: false,
            afterChange: this.afterChangeHandler,
            beforeChange: this.beforeChangeHandler,
        };

        if (this.state.loaded) {
            return (
                <section className={"work-slider-section " + ((this.props.transparent) ? "transparent" : "")}>
                    <div className="container">
                        <Title
                            text        = "Some of our Latest Projects"
                            titleType   = "h2"
                        />
    
                        <Slider {...settings} ref={ slider => (this.slider = slider) }>
                            {this.state.posts.map( (post, key) => {
                                return (
                                    <WorkSliderCard
                                        key         = {key}
                                        slug        = {post.slug}
                                        image       = {post.acf.main_image}
                                        title       = {post.title.rendered}
                                        services    = {post.acf.services_tags}
                                        active      = {((this.state.currentSlide === key) ? true : false)}
                                    />
                                )

                            })}
                        </Slider>
                        
                        <div className="slider-scrollbar">
                            <div className="row align-center">
                                <div className="col-4">
                                    <div className="slider-bar">
                                        <Draggable
                                            axis                = "x"
                                            bounds              = 'parent'
                                            onDrag              = {this.handleDrag}
                                            onStop              = {this.stopDrag}
                                        >
                                            <div id="scroll-item">
                                                <span>{this.state.numberSlide+1}-{this.state.posts.length}</span>
                                            </div>
                                        </Draggable>
                                    </div>
                                </div>
    
                                <div className="col-1">
                                    <div className="slider-buttons">
                                        <div id="left-button" className="nav-button" onClick={() => this.navSlider("back")}>
                                            <div className="inner-button">
                                                <svg viewBox="0 0 10.212 10.213">
                                                    <defs>
                                                        <clipPath id="clip-path">
                                                        <rect id="Rectangle_1048" data-name="Rectangle 1048" width="10.213" height="10.213" fill="#fff" stroke="#707070" strokeWidth="1"/>
                                                        </clipPath>
                                                    </defs>
                                                    <g id="Mask_Group_25" data-name="Mask Group 25" transform="translate(10.212 10.213) rotate(180)" clipPath="url(#clip-path)">
                                                        <path id="launch-arrow" d="M7.427,0l2.785,2.321L7.427,4.642V2.785H0V1.857H7.427Z" transform="translate(0 2.785)" fill="#fff"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        
                                        <div id="right-button" className="nav-button" onClick={() => this.navSlider("next")}>
                                            <div className="inner-button">
                                                <svg viewBox="0 0 10.212 10.213">
                                                    <defs>
                                                        <clipPath id="clip-path">
                                                        <rect id="Rectangle_1048" data-name="Rectangle 1048" width="10.213" height="10.213" fill="#fff" stroke="#707070" strokeWidth="1"/>
                                                        </clipPath>
                                                    </defs>
                                                    <g id="Mask_Group_25" data-name="Mask Group 25" transform="translate(10.212 10.213) rotate(180)" clipPath="url(#clip-path)">
                                                        <path id="launch-arrow" d="M7.427,0l2.785,2.321L7.427,4.642V2.785H0V1.857H7.427Z" transform="translate(0 2.785)" fill="#fff"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        } else {
            return null;
        }

    }
}

export default WorkSlider;