import React, { Component } from 'react';
import Title from "../typography/titleSection"
import Button from '../../buttons/buttons';
import SecondaryText from '../typography/secondaryText';

const testimonialCont = (props) => {
    return (
        <div className="testimonial-cont">
            <p>{props.name}</p>
            <span>
                <SecondaryText
                    text    = {props.position + " |"}
                    white   = {true}
                />
            </span>
            
            <span>
                <SecondaryText
                    text = {props.company}
                />
            </span>
        </div>
    )
}

const servicesCont = (props) => {
    return (
        <div className="services-list">
            {props && props.map( (service, key) => {
                const timing = (0.4 + key * 0.05) + "s";
                return (
                    <div key={key} className="service-entry">
                        <div style={{ transitionDelay: timing }} className="service-title">
                            <span>{service.title}</span>
                        </div>
                
                    </div>
                )
            })}
        </div>
    )
}

class ImageText extends Component {

    constructor (props) {
        super(props);

        this.getGallery = this.getGallery.bind(this);
        this.navSlider  = this.navSlider.bind(this);
        this.setSlider  = this.setSlider.bind(this);

        this.state = {
            galleryMode: false,
            currentSlide: 0,
        }
    }

    navSlider( dir ) {
        
        // console.log ("TEST: " + ( this.state.currentSlide >= 0 && this.state.currentSlide <= (this.props.image.length-1) ));
        // console.log(this.state.currentSlide);
        // console.log(this.props.image.length);
        
        if ( dir ) {
            if ( this.state.currentSlide < (this.props.image.length-1) ) {
                this.setState({
                    currentSlide: this.state.currentSlide + 1
                }, () => {
                    this.setSlider();
                });
            }
        } else {
            if ( this.state.currentSlide > 0 ) {
                this.setState({
                    currentSlide: this.state.currentSlide - 1
                }, () => {
                    this.setSlider();
                });
            }
        }
    }

    setSlider() {
        var obj         = this.slider;
        var parent      = obj.parentElement;
        var parentWidth = parent.getBoundingClientRect().width;
        var movePos     = parentWidth * this.state.currentSlide;

        obj.style.transform = "translate(" + (-movePos) + "px,0)"
    }

    getGallery( images ) {
        if ( images.length > 1 ) {
            // gallery mode

            return (
                <div className="gallery-container">
                    <div className="inner-gallery-cont" ref={ slider => (this.slider = slider)}>
                        {images.map( (imageData, key) => {
                            return (
                                <img key={key} src={ imageData.url} alt={(imageData.alt) ? imageData.alt : this.props.secondary}/>
                            )
                        })}
                    </div>

                    <div className="slider-buttons">
                        <div id="left-button" className={"nav-button " + ((this.state.currentSlide === 0 ) ? "disabled" : "")} onClick={() => this.navSlider(false)}>
                            <div className="inner-button">
                                <svg viewBox="0 0 10.212 10.213">
                                    <defs>
                                        <clipPath id="clip-path">
                                        <rect id="Rectangle_1048" data-name="Rectangle 1048" width="10.213" height="10.213" fill="#fff" stroke="#707070" strokeWidth="1"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Mask_Group_25" data-name="Mask Group 25" transform="translate(10.212 10.213) rotate(180)" clipPath="url(#clip-path)">
                                        <path id="launch-arrow" d="M7.427,0l2.785,2.321L7.427,4.642V2.785H0V1.857H7.427Z" transform="translate(0 2.785)" fill="#fff"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        
                        <div id="right-button" className={"nav-button " + ((this.state.currentSlide === (this.props.image.length-1) ) ? "disabled" : "")} onClick={() => this.navSlider(true)}>
                            <div className="inner-button">
                                <svg viewBox="0 0 10.212 10.213">
                                    <defs>
                                        <clipPath id="clip-path">
                                        <rect id="Rectangle_1048" data-name="Rectangle 1048" width="10.213" height="10.213" fill="#fff" stroke="#707070" strokeWidth="1"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Mask_Group_25" data-name="Mask Group 25" transform="translate(10.212 10.213) rotate(180)" clipPath="url(#clip-path)">
                                        <path id="launch-arrow" d="M7.427,0l2.785,2.321L7.427,4.642V2.785H0V1.857H7.427Z" transform="translate(0 2.785)" fill="#fff"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            var imageData = images[0];
            
            // console.log("NOT GALLERY");

            return (
                <img src={ imageData.url} alt={(imageData.alt) ? imageData.alt : this.props.secondary}/>
            );
        }

        // var imageData = images[0];
        
        // console.log("NOT GALLERY");

        // return (
        //     <img src={ imageData.url} alt={(imageData.alt) ? imageData.alt : this.props.secondary}/>
        // );
    }
    
    render() {
        // Default Left Section
        // Change Classes (state: imageLeft) to flip sections
        const leftSection = ( props ) => {
            return (
                <div className={"col-1 content-col " + ((props.imageLeft) ? "right-section" : "")}>
                    <div className={"space-container " + ((props.imageLeft) ? "right-space" : "left-space")}>
                        <div className={"half-container " + ((props.imageLeft) ? "right-section" : "left-section")}>
                            <div className="text-container">
                                <Title
                                    secondary   = {props.secondary}
                                    whiteSec    = {(props.whiteSec) ? true : false}
                                    text        = {props.text}
                                    titleType   = "h2"
                                    classes     = {((props.overlapTitle) ? "overlap-title" : "")}
                                />

                                {(this.props.testimonial) ? 
                                    testimonialCont(props.testimonialInfo)
                                : ""}

                                
                                {(this.props.content) ? 
                                    <p>{this.props.content}</p>  
                                : ""}  
                                
                                {(this.props.serviceIntro) ? 
                                    servicesCont(props.servicesInfo)
                                : ""}

                                {(this.props.buttonLink) ? 
                                    <Button
                                        classes = {'text-button ' + props.buttonClasses}
                                        link    = {props.buttonLink}
                                        text    = {props.buttonText}
                                    />    
                                : ""}   
                            </div>
                        </div>
                    </div>
                </div>
            )
        };

        // Default Right Section
        // Check if single image or gallery
        const rightSection = ( props ) => {
            // console.log(props.image);
            var imageData = this.getGallery(props.image);

            return (
                <div className={"col-1 image-col " + ((props.imageLeft) ? "left-section " : "")  + ((this.state.galleryMode) ? "gallery-col" : "")}>
                    <div className={"bg-img " + ((props.containerFit) ? "space-container " : "") + ((props.imageLeft && props.containerFit) ? "left-space" : "right-space")}>
                        <div className="image-cont">
                            {imageData}
                        </div>
                    </div>
                </div>
            )
        };

        return (
            <section className={"image-text-section " + ((this.props.overlapTitle) ? " overlap-title" : "") + ((this.props.serviceIntro) ? " service-intro" : "")}>
                <div className="row align-center">
                    { leftSection(this.props) }
                    { rightSection(this.props) }                    
                </div>                                 
            </section>
        );
    }
}

export default ImageText;
