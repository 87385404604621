import React, { Component } from 'react';

import Header 			    from '../components/header/header';
// import HeroPageShift        from '../components/sections/page/heroPageShift';
// import ContactDetails       from '../components/sections/page/contactDetails';
import Animate              from '../components/animation/animate';
import Footer               from '../components/footer/footer';
// import WpSeo                from '../utils/wp-seo';
// import DataApi from '../utils/data-api';
import Title from '../components/sections/typography/titleSection';
import ContactForm from '../components/sections/forms/contactForms';
import SecondaryText from '../components/sections/typography/secondaryText';
import FadeIn from '../components/sections/page/fade-in';

class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true
        };
    }

    componentDidMount() {
        // var fetchPageData = DataApi.getPost( 'pages', 'contact' );

        // Promise.resolve(fetchPageData).then( response => {
        //     this.setState({
        //         page:   response[0],
        //     })
        // }).then( () => {
        //     this.setState({
        //         loaded: true,
        //     })
        // });
    }

    render() {
        // var page_title = "contact us";

        if ( this.state.loaded ) {
            return (
                <div className="page-container white-section contact-page">
                    {/* <WpSeo
                        slug        = "contact"
                        pageHeaders = {true}
                        pageObject  = {this.state.page.yoast_meta}
                    /> */}
    
                    <Header />

                    <div className="side-page-name">
                        <SecondaryText
                            text = "Contact Us"
                            white = {true}
                        />
                    </div>

                    <Animate type="load">
                        {/* <ContactDetails /> */}
                        <section>
                            <div className="container">
                                <div className="narrow-text-cont center-block mt-xl">
                                    <FadeIn>
                                        <SecondaryText
                                            text    = "Info & Request"
                                            white   = {true}
                                        />
                                        
                                        <Title
                                            // secondary   = "Info & Request"
                                            text        = "Contact Us"
                                            titleType   = "h1"
                                            whiteSec    = {true}
                                            classes     = "lg-text"
                                        />
                                    </FadeIn>
                                </div>

                                <div className="inset-container contact-section mt-xl">
                                    <div className="row">
                                        <div className="col-1">
                                            {/* <FadeIn>
                                                <h2>Headquarters</h2>
                                                <p>Cannanalytica, Office 6 The Peak Business Centre</p>
                                                <p>Triq il-Kappella tal-Mirakli, Attard. Malta</p>
                                            </FadeIn> */}

                                            <FadeIn>
                                                <h2>Contact</h2>
                                                {/* <p>T. <a href="tel:0035699458079">+356 9945 8079</a></p> */}
                                                <p>E. <a href="mailto:info@cannanalytica.com">info@cannanalytica.com</a></p>
                                            </FadeIn>
                                        </div>

                                        <div className="col-1">
                                            {/* <FadeIn>
                                                <h2>Contact</h2>
                                                <p>T. <a href="tel:0035699458079">+356 9945 8079</a></p>
                                                <p>E. <a href="mailto:info@cannanalytica.com">info@cannanalytica.com</a></p>
                                            </FadeIn> */}
                                        </div>
                                    </div>

                                    <FadeIn>
                                        <ContactForm />
                                    </FadeIn>
                                </div>
                            </div>
                        </section>
                    </Animate>
    
                    <Footer />
                </div>
            );
        } else {
            return null;
        }

    }

}

export default ContactPage;
