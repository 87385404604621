import React, { Component } from 'react';
// import { Link }             from 'react-router-dom';

import Header 			    from '../components/header/header';
import DataApi              from '../utils/data-api';
import Footer               from '../components/footer/footer';
import Animate              from '../components/animation/animate';
// import WpSeo                from '../utils/wp-seo';
import HeroCircle           from '../components/sections/page/heroCircle';
import ImageText            from '../components/sections/page/imageText';
// import testImg              from '../assets/images/test-image.png';
import aboutImg             from '../assets/images/vision.jpg';
// import footerCta            from '../assets/images/footer-cta.jpg';
import consultingSVG        from '../assets/images/consulting-services.svg';
import logo1                from '../assets/images/logos/logo-1.svg';
import logo2                from '../assets/images/logos/logo-2.svg';
import logo3                from '../assets/images/logos/logo-3.svg';
import Title                from '../components/sections/typography/titleSection';
import SecondaryText        from '../components/sections/typography/secondaryText';
import FooterCta from '../components/sections/page/footerCta';
import FadeIn from '../components/sections/page/fade-in';

class AboutPage extends Component {

    constructor(props) {
        super(props);

        this.handleScroll     = this.handleScroll.bind(this);

        this.state = {
            loaded: false,
            scroll: 0
        };
    }

    componentDidMount() {
        var fetchPageData = DataApi.getPost( 'pages', 'about' );

        Promise.resolve(fetchPageData).then( response => {
            this.setState({
                page:   response[0],
            })
        }).then( () => {
            this.setState({
                loaded: true,
            })
        });

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(){
        
        if ( (window.pageYOffset/window.innerHeight * 2) < 100 ) {
            this.setState({
                scroll: window.pageYOffset/window.innerHeight
            })
        }
    }

    render() {
        if ( this.state.loaded ) {
            return (
                <div className="page-container white-section blank">
                    {/* <WpSeo
                        slug        = "about-us"
                        pageHeaders = {true}
                        pageObject  = {this.state.page.yoast_meta}
                    /> */}

                    <Header />

                    <div className="side-page-name">
                        <SecondaryText
                            text = "Vision & Mission"
                            white = {true}
                        />
                    </div>

                    <div className="side-padding full-height scroll-container center light-button">
                        <div onClick={() => window.scroll({
                            top: window.innerHeight, 
                            left: 0, 
                            behavior: 'smooth'
                        })} className="scroll-down-cont">
                            <div className="arrow-cont down-arrow"></div>
                        </div>
                    </div>

                    <div className="side-padding full-height scroll-container light-button">
                            <div onClick={() => window.scroll({
                                top: window.innerHeight, 
                                left: 0, 
                                behavior: 'smooth'
                            })} className="scroll-down-cont">
                                <div className="arrow-cont down-arrow"></div>

                                <SecondaryText
                                    text    = "Scroll"
                                    white   = {true}
                                />
                            </div>
                        </div>

                    <Animate classes="hero-anim" type="load">
                        <HeroCircle
                            fullHeight  = {true}
                            text        = {"Vision & Mission"}
                            scale       = {this.state.scroll}
                        />
                    </Animate>
                    
                    <Animate classes="image-text-anim">
                        <ImageText
                            secondary       = "About The Company"
                            whiteSec        = {true}
                            text            = {"Cannanalytica is a Malta, London and Portugal based consultancy firm specialising in the Global medical cannabis industry."}
                            content         = {"We offer our regulatory, EU GMP certification and business development consultancy services to investors and Licensed Producers who are currently present or planning to enter into the medical cannabis space. Our Team of business executives, regulatory experts and QPs/QAs with vast experience in the pharma industry are perfectly placed to advise our clients on medical cannabis projects within Europe."}
                            image           = {[{url: aboutImg}]}
                            imageLeft       = {false}
                            overlapTitle    = {false}
                            containerFit    = {false}
                            buttonLink      = "contact"
                            buttonText      = "Get in Touch"
                            buttonClasses   = "hero-button text-button standard-hero"
                        />
                    </Animate>
                    
                    <section className="industry-section">
                        <div className="container">
                            <div className="inset-container">
                                <div className="row">
                                    <div className="col-1 text-center">
                                        <FadeIn>
                                            <div className="xl-text">
                                                <h2>€115.7</h2>
                                            </div>

                                            <div className="secondary-text white-color">
                                                <span>Billion</span>
                                            </div>
                                        </FadeIn>
                                    </div>

                                    <div className="col-1">
                                        <FadeIn>
                                            <p className="md-text">The Medical Cannabis Industry is as of 2028 forecasted to be worth a total value of € 115.7 billion euros.</p>
                                        </FadeIn>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1">
                                        <FadeIn>
                                            <img src={consultingSVG} alt="Full end-to-end consulting services"/>
                                            <p>Full end-to-end consulting services including Certification and Compliance, and Audits for existing facilities.</p>
                                        </FadeIn>
                                    </div>

                                    <div className="col-1">
                                        <FadeIn>
                                            <h3 className="xl-text">10 +</h3>
                                            <p>Over ten international medical cannabis companies applying for a license in Malta in under one year.</p>
                                        </FadeIn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container">
                            <div className="inset-container">
                                <div className="title-container">
                                    <FadeIn>
                                        <SecondaryText
                                            text    = "Our Clients"
                                            white   = {true}
                                        />
                                    </FadeIn>
                                        
                                    <Title
                                        text        = "Industry Leaders That Have Trusted Us"
                                        titleType   = "h2"
                                    />
                                </div>
                            </div>

                            <FadeIn>
                                <div className="brand-icon-cont pt-md">
                                    <div className="logo-container">
                                        <img src={logo1} alt="Supreme"/>
                                    </div>
                                    
                                    <div className="logo-container">
                                        <img src={logo2} alt="Hoshi"/>
                                    </div>
                                    
                                    <div className="logo-container">
                                        <img src={logo3} alt="Emmac"/>
                                    </div>
                                </div>
                            </FadeIn>
                        </div>
                    </section>

                    <FooterCta />

                    {/* {(this.state.page.acf.page_sections) ? 
                        <FlexibleLayout
                            data = {this.state.page.acf.page_sections}
                        />
                    : ""}
                    
                    <PeopleCarousel />

                    <Animate classes="work-slider-anim">
                        <WorkSlider />
                    </Animate>

                    <TextButtonBanner
                        buttonLink  = "/contact"
                        buttonText  = "Contact"
                        // whiteBg     = {true}
                    /> */}

                    <Footer />
                </div>
            );
        } else {
            return null;
        }
    }
}

export default AboutPage;
