import React, { Component } from 'react';

// import SimplexNoise         from "simplex-noise";
import Title                from "../typography/titleSection";
// import LetterAnim           from "../../animation/letterAnim";
// import SecondaryText from '../typography/secondaryText';
// import bgImg                from "../../../assets/images/bg3d.jpg"

class HeroCircle extends Component {

    // constructor(props) {
    //     super(props);

        // this.handleScroll     = this.handleScroll.bind(this);

        // this.state = {
        //     scroll: 0
        // };
    // }

    render() {
        return (
            <div>
                <div className={"hero-container-text hero-circle " + ( (this.props.image) ? "image-mode " : "normal-mode " ) + ( (this.props.fullHeight) ? "screen-height " : "" ) + ( (this.props.classes) ? this.props.classes : "" )}>   
                    {(!this.props.image) ? 
                        <div style={{transform: "translate(-50%, " + (0*this.props.scale*3) + "%) scale(" + (1+(this.props.scale * 2)) + ")", opacity: (this.props.scale + 0.1)}} className="inner-circle">

                        </div>
                    : ""}

                    <div className="inner-hero-shift">
                        <div className={"title-section-cont container "  + ( (this.props.fullHeight) ? "screen-height " : "" ) }>
                            <div className={"inner-hero " + ((this.props.image) ? "image-hero" : "")}>
                                <Title
                                    secondary   = {this.props.secondary}
                                    text        = {this.props.text}
                                    titleType   = "h1"
                                    classes     = "text-center"
                                    whiteSec     = {true}
                                    delay       = {1.5}
                                />                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeroCircle;
