import React, { Component }     from 'react';
import { withRouter }       from 'react-router-dom';



// import HeroPageShift        from '../components/sections/page/heroPageShift';
import Header 			        from '../components/header/header';
// import PeopleCarousel       from '../components/pages/people/peopleCarousel';
// import WorkSlider           from '../components/pages/work/work-slider';
// import DataApi                  from '../utils/data-api';
// import FlexibleLayout       from '../components/sections/page/flexible-layout';
// import TextButtonBanner     from '../components/sections/page/textButtonBanner';
import Footer                   from '../components/footer/footer';
import Animate                  from '../components/animation/animate';
// import WpSeo                from '../utils/wp-seo';
// import HeroCircle from '../components/sections/page/heroCircle';
import Title                    from '../components/sections/typography/titleSection';
// import testImg                  from '../assets/images/test-image.png';
// import licenses                 from '../assets/images/licenses.jpg';
import legislation              from '../assets/images/licenses.jpg';
import certification            from '../assets/images/vision.jpg';
// import aboutImg             from '../assets/images/vision.jpg';
// import footerCta            from '../assets/images/footer-cta.jpg';
import FooterCta from '../components/sections/page/footerCta';
import FadeIn from '../components/sections/page/fade-in';
import SecondaryText from '../components/sections/typography/secondaryText';


const ContentSection = (props) => {
    return (
        <section className="content-section">
            <FadeIn>
                <h3>{props.title}</h3>
                <div dangerouslySetInnerHTML={{__html: props.text}}></div>
            </FadeIn>
        </section>
    )
}

const LightSection = () => {
    return (
        <section className="light-section white-section">
            <div className="container">
                <section>
                    <FadeIn>
                        <div className="row">
                            <div className="col-2">
                                <Title
                                    text        = "Licenses & Legislation"
                                    titleType   = "h2"
                                    classes     = "md-text title-why"
                                />
                            </div>

                            <div className="col-3">
                                <p>As more countries are issuing licenses for medical cannabis cultivation and import, our Team at Cannanalytica can help investors assess the different opportunities and decide on which territories are suitable for their projects. After identifying the territory, our Team at Cannanalytica can project manage the application phase for our clients up the granting of the license by the regulator of the chosen territory.</p>
                            </div>
                        </div>
                    </FadeIn>
                </section>

                <section>
                    <div className="row">
                        <div className="col-1">
                            <ContentSection
                                title   = "Legislation"
                                text    = "<p>Legislation in different EU territories – Medical Cannabis legislation in Europe is a complex landscape, with different laws applying to different territories. Moreover, legislation and regulation are constantly changing as different countries open up to medical cannabis in one form or another. The Cannanalytica team of regulatory experts assist our clients by guiding them through the different legislative frameworks to help them understand what can be done where, by who and how.</p>"
                            />
                            <ContentSection
                                title   = "License Applications"
                                text    = "<p>As more countries are issuing licenses for medical cannabis cultivation and import, our Team at Cannanalytica can help investors assess the different opportunities and decide on which territories are suitable for their projects. After identifying the territory, our Team at Cannanalytica can project manage the application phase for our clients up the granting of the license by the regulator of the chosen territory.</p>"
                            />
                        </div>

                        <div className="col-1">
                            <div className="static-cont">
                                <Animate classes="image-anim">
                                    <div className="image-wrapper">
                                        <div className="image-container">
                                            <div className="inner-img">
                                                <img src={legislation} alt="Legislation"/>
                                            </div>
                                        </div>
                                    </div>
                                </Animate>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
}

const DarkSection = () => {
    return (
        <section className="dark-section">
            <div className="container">
                <section>
                    <FadeIn>
                        <div className="row">
                            <div className="col-2">
                                <Title
                                    text        = "Certification and Compliance"
                                    titleType   = "h2"
                                    classes     = "md-text title-why"
                                />
                            </div>

                            <div className="col-3">
                                <p>We offer our regulatory, EU GMP certification and business development consultancy services to investors and Licensed Producers who are currently present or planning to enter into the medical cannabis space. Our Team of business executives, regulatory experts and QPs/QAs with vast experience in the pharma industry are perfectly placed to advise our clients on medical cannabis projects within Europe.</p>
                            </div>
                        </div>
                    </FadeIn>
                </section>

                <section>
                    <div className="row">
                        <div className="col-1">
                            <ContentSection
                                title   = "Certification and Compliance"
                                text    = "<p>EU GMP certification and compliance are the cornerstones of the medical cannabis industry in Europe. All medical cannabis sold in Europe needs to be produced under EU GMP and later tested in an EU GMP facility before being released, together with GDP certification for warehousing and distribution.</p><br><p>CannAnalyica has a team of experienced professionals who assist and advise our clients to ensure compliance at every stage of their business processes such as:<br><br>• Analytical Testing <br>• Batch Release <br>• Warehouse & Eu Distribution <br>• Pharmaceutical Packaging – Audits of Manufacturing Sites <br>• Pharmacovigliance Complaince – GMP Ancillary Services</p>"
                            />
                            <ContentSection
                                title   = "Audits for existing facilities"
                                text    = "<p>Cannanalytica can assist clients by auditing their facilities before commencing the EU GMP certification process. The objective of these audits is to identify the gaps between the facility’s standards and processes and the EU GMP benchmark. The Cannanalytica Team of QP’s and QA’s will then advise our clients on how to bridge the gaps in the most efficient and time effective manner in order to ensure achieving EU GMP standards in the quickest timeline possible.</p>"
                            />
                        </div>

                        <div className="col-1">
                            <div className="static-cont">
                                <Animate classes="image-anim">
                                    <div className="image-wrapper">
                                        <div className="image-container">
                                            <div className="inner-img">
                                                <img src={certification} alt="Certification"/>
                                            </div>
                                        </div>
                                    </div>
                                </Animate>
                                {/* <img src={certification} alt="Certification"/> */}
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </section>
    )
}

class ServicesPage extends Component {

    constructor(props) {
        super(props);

        this.handleScroll     = this.handleScroll.bind(this);

        this.state = {
            loaded: true,
            scroll: 0,
            colourMode: ""
        };
    }

    componentDidMount() {

        if ( this.props.location.state) {
            this.setState({
                colourMode: this.props.location.state.colourMode
            })

            setTimeout( this.scrollSection, 3000 );
        }
        // var fetchPageData = DataApi.getPost( 'pages', 'about' );

        // Promise.resolve(fetchPageData).then( response => {
        //     this.setState({
        //         page:   response[0],
        //     })
        // }).then( () => {
        //     this.setState({
        //         loaded: true,
        //     })
        // });

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    changeMode(colour) {
        this.setState({
            colourMode: colour
        })
    }

    handleScroll(){
        this.setState({
            scroll: window.pageYOffset
        })
    }

    scrollSection() {
        window.scroll({
            top: window.innerHeight, 
            left: 0, 
            behavior: 'smooth'
        });
    }

    render() {
        if ( this.state.loaded ) {
            return (
                <div className={"page-container " + ((this.state.colourMode === "light") ? "white-section" : "dark-gradient-section")}>
                    {/* <WpSeo
                        slug        = "about-us"
                        pageHeaders = {true}
                        pageObject  = {this.state.page.yoast_meta}
                    /> */}

                    <Header />

                    <div className="side-page-name">
                        <SecondaryText
                            text = "Services"
                            white = {true}
                        />
                    </div>

                    <Animate classes="hero-anim" type="load">
                        <div className="screen-height center-content">
                            <FadeIn>
                                <div className={"row align-center hover-action " + ((this.state.colourMode === "dark") ? "dark-bg" : "")}>
                                    <div className="col-1">
                                        <div onClick={() => this.scrollSection()} onMouseOver={() => this.changeMode("light")} className={"clip-circle-image light-circle text-center " + ((this.state.colourMode === "light") ? "active" : "")}>
                                            <h2>Licenses & Legislation</h2>
                                        </div>
                                    </div>

                                    <div className="col-1">
                                        <div onClick={() => this.scrollSection()} onMouseOver={() => this.changeMode("dark")} className={"clip-circle-image dark-circle text-center " + ((this.state.colourMode === "dark") ? "active" : "")}>
                                            <h2>Certification & Compliance</h2>
                                        </div>
                                    </div>
                                </div>
                            </FadeIn>
                        </div>

                        <div className="side-padding full-height scroll-container">
                            <div onClick={() => window.scroll({
                                top: window.innerHeight, 
                                left: 0, 
                                behavior: 'smooth'
                            })} className="scroll-down-cont">
                                <div className="arrow-cont down-arrow"></div>

                                <SecondaryText
                                    text    = "Scroll"
                                    white   = {true}
                                />
                            </div>
                        </div>
                    </Animate>

                    {(this.state.colourMode === "light") ? 
                        <div>
                            <LightSection />
                            <DarkSection/> 
                        </div>
                    :   
                        <div>
                            <DarkSection />
                            <LightSection/> 
                        </div>
                    }
                    
                    <FooterCta />

                    <Footer />
                </div>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(ServicesPage);
