import React, { Component } from 'react';
import SecondaryText from './secondaryText';
import FadeIn from '../page/fade-in';

class Title extends Component {
    render() {
        return (
            <div className={ "title-section " + ((this.props.classes) ? this.props.classes : "") }>
                <SecondaryText
                    text    = {this.props.secondary}
                    white   = {this.props.whiteSec}
                />

                {/* { (this.props.titleType === "h1") ? <h1>{this.props.text}</h1> : "" }
                { (this.props.titleType === "h2") ? <h2>{this.props.text}</h2> : "" }
                { (this.props.titleType === "h3") ? <h3>{this.props.text}</h3> : "" } */}
                { (this.props.titleType === "h1") ? <h1><FadeIn wordAnim={true} delay={ this.props.delay }>{this.props.text}</FadeIn></h1> : "" }
                { (this.props.titleType === "h2") ? <h2><FadeIn wordAnim={true} delay={ this.props.delay }>{this.props.text}</FadeIn></h2> : "" }
                { (this.props.titleType === "h3") ? <h3><FadeIn wordAnim={true} delay={ this.props.delay }>{this.props.text}</FadeIn></h3> : "" }
            </div>
        );
    }
}

export default Title;
