import React, { Component }     from 'react';
import { TweenMax }             from 'gsap'; 
import { withRouter }           from 'react-router-dom';

class MouseFollow extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleMouseMove    = this.handleMouseMove.bind(this, this.props.handleMouseMove);
        this.linkHover          = this.linkHover.bind(this, this.props.linkHover);
        this.linkHoverOut       = this.linkHoverOut.bind(this, this.props.linkHoverOut);
        this.accentHover        = this.accentHover.bind(this, this.props.accentHover);
        this.accentHoverOut     = this.accentHoverOut.bind(this, this.props.accentHoverOut);
    }

    componentDidMount() {
        this.setLinks();

        if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            this.setState({
                isSafari: true
            })
        }

        window.addEventListener('mousemove', this.handleMouseMove);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setLinks();
        }
    }

    setLinks() {
        var links           = document.getElementsByTagName('a');
        var accentSection   = document.getElementsByClassName('link-hover');

        for (var i = 0; i < links.length; i++) {
            var childElement = links[i];
            childElement.addEventListener('mouseenter', this.linkHover);
            childElement.addEventListener('mouseleave', this.linkHoverOut);
        }

        for (var x = 0; x < accentSection.length; x++) {
            var childElementX = accentSection[x];
            childElementX.addEventListener('mouseenter', this.linkHover);
            childElementX.addEventListener('mouseleave', this.linkHoverOut);
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseMove);

        var links           = document.getElementsByTagName('a');
        var accentSection   = document.getElementsByClassName('link-hover');

        for (var i = 0; i < links.length; i++) {
            var childElement = links[i];
            // childElement.removeEventListener('mouseover', this.linkHover);
            childElement.removeEventListener('mouseenter', this.linkHover);
            childElement.removeEventListener('mouseleave', this.linkHoverOut);
        }

        for (var x = 0; i < accentSection.length; x++) {
            var childElementX = accentSection[x];
            childElementX.addEventListener('mouseenter', this.linkHover);
            childElementX.addEventListener('mouseleave', this.linkHoverOut);
        }
    }
    
    handleMouseMove(event, handler) {
        // console.log("asdasdasdas");

        // console.log(e);
        // console.log(handler);

        this.setState({
            top: handler.clientY,
            left: handler.clientX
        })
    }

    linkHoverOut() {
        // TweenMax.to(".inner-mouse", 1, { "width": 20, ease: "Expo.easeOut" }, { "height": 20, ease: "Expo.easeOut" });
        TweenMax.to(".inner-mouse", 1, { "width": 20, ease: "Expo.easeOut" });
        TweenMax.to(".inner-mouse", 1, { "height": 20, ease: "Expo.easeOut" });

        this.setState({
            pointer: false
        })
    }

    linkHover(event, handler) {
        var linkSize = handler.toElement.offsetWidth;

        if ( linkSize < 50 ) {
            linkSize = 50;
        } else if (linkSize > 100) {
            linkSize = 100;
        }

        TweenMax.to(".inner-mouse", 1, { "width": linkSize, ease: "Expo.easeOut" });
        TweenMax.to(".inner-mouse", 1, { "height": linkSize, ease: "Expo.easeOut" });

        this.setState({
            pointer: true
        })
    }
    
    accentHoverOut() {
        this.setState({
            pointerAccent: false
        })
    }

    accentHover() {
        // console.log("LINKKK");

        this.setState({
            pointerAccent: true
        })
    }

    render() {
        const positionStyle = {
            transform : "translateX(" + this.state.left + "px) translateY(" + this.state.top + "px)"
        }

        return (
            <div style={positionStyle} className={"mouse-follower " + ((this.state.pointer) ? "hide-bg " : "") + ((this.props.loaded) ? "loaded " : "") + ((this.props.active) ? "active " : "")  + ((this.state.pointerAccent) ? "accent-pointer " : "") + ((this.state.isSafari) ? "hidden " : "")}>
                <div className="inner-mouse"></div>
            </div>
        );
    }
}

export default withRouter(MouseFollow);
