import React, { Component } from 'react';
// import { Link }             from 'react-router-dom';
import Header 			    from '../components/header/header';
import Animate              from '../components/animation/animate';
import HeroCircle           from '../components/sections/page/heroCircle';
import MaltaImg             from '../assets/images/malta.jpg';
import Title                from '../components/sections/typography/titleSection';
// import footerCta            from '../assets/images/footer-cta.jpg';
import SecondaryText        from '../components/sections/typography/secondaryText';
import Footer               from '../components/footer/footer';
import logo1                from '../assets/images/logos/logo-1.svg';
import logo2                from '../assets/images/logos/logo-2.svg';
import logo3                from '../assets/images/logos/logo-3.svg';
// import mailLogo                from '../assets/images/mail.svg';
// import servicesLogo                from '../assets/images/services.svg';
import FooterCta from '../components/sections/page/footerCta';
import FadeIn from '../components/sections/page/fade-in';

class WhyMaltaPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded:         true,
        };
    }
    
    componentDidMount() {
        // var fetchNewsData   = DataApi.getPosts( 'posts', 5 );
        // var fetchPageData   = DataApi.getPost( 'pages', 'home' );
        
        // Promise.all([ fetchPageData, fetchNewsData ]).then((response) => {
        //     this.setState({
        //         page:   response[0][0],
        //         news:   response[1]
        //     });
        // }).then( () => {

        //     this.setState({
        //         loaded:     true,
        //     })
        // });
    }

    render() {
        // if ( this.state.loaded ) {
        return (
            <div className="page-container dark-section why-malta-page">
                    {/* <WpSeo
                        slug        = "about-us"
                        pageHeaders = {true}
                        pageObject  = {this.state.page.yoast_meta}
                    /> */}

                    <Header />

                    <div className="side-page-name">
                        <SecondaryText
                            text = "Why Malta?"
                            white = {true}
                        />
                    </div>

                    <div className="side-padding full-height scroll-container center light-button">
                        <div onClick={() => window.scroll({
                            top: window.innerHeight, 
                            left: 0, 
                            behavior: 'smooth'
                        })} className="scroll-down-cont">
                            <div className="arrow-cont down-arrow"></div>
                        </div>
                    </div>

                    <Animate classes="hero-anim" type="load">
                        <HeroCircle
                            fullHeight  = {true}
                            secondary   = "Why Malta?"
                            text        = {"The most progressive legislation on medical cannabis in Europe "}
                            image       = {MaltaImg}
                        />
                    </Animate>

                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-1">
                                    <FadeIn>
                                        <Title
                                            text        = "10+ Companies"
                                            titleType   = "h2"
                                            classes     = "md-text title-why"
                                        />

                                        <p>Over ten international medical cannabis companies applying for a license in Malta in under one year.</p>

                                        <Title
                                            text        = "Info on Legislation"
                                            titleType   = "h2"
                                            classes     = "mt-lg"
                                        />

                                        <p>Information on the new legislation can be found here.</p>
                                    </FadeIn>
                                </div>

                                <div className="col-1">
                                    <div className="content-section">
                                        <FadeIn>
                                            <Title
                                                text        = "Distribution through Europe"
                                                titleType   = "h2"
                                            />

                                            <p>We offer our regulatory, EU GMP certification and business development consultancy services to investors and Licensed Producers who are currently present or planning to enter into the medical cannabis space. Our Team of business executives, regulatory experts and QPs/QAs with vast experience in the pharma industry are perfectly placed to advise our clients on medical cannabis projects within Europe.</p>
                                        </FadeIn>
                                    </div>

                                    {/* <div className="content-section mt-lg">
                                        <FadeIn>
                                            <Title
                                                text        = "Experienced team of middle market professionals"
                                                titleType   = "h2"
                                            />

                                            <p>We offer our regulatory, EU GMP certification and business development consultancy services to investors and Licensed Producers who are currently present or planning to enter into the medical cannabis space. Our Team of business executives, regulatory experts and QPs/QAs with vast experience in the pharma industry are perfectly placed to advise our clients on medical cannabis projects within Europe.</p>
                                        </FadeIn>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="white-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-1">
                                    <FadeIn>
                                        <Title
                                            text        = "Capable, Reliable & Experience"
                                            titleType   = "h2"
                                            classes     = "md-text title-why"
                                        />
                                    </FadeIn>
                                </div>

                                <div className="col-1">
                                    <FadeIn>
                                        <Title
                                            text        = "Cannanalytica offers a full set of set of services for companies who are planning to apply for a license in Malta:"
                                            titleType   = "h3"
                                        />
                                    </FadeIn>

                                    <div className="number-list">
                                        <FadeIn>
                                            <div className="row align-center">
                                                <div className="col-1">
                                                    <span>01.</span>
                                                </div>

                                                <div className="col-5">
                                                    <p>Qualification of the entire pharmaceutical supply chain both upstream and downstream including audits (if required).</p>
                                                </div>
                                            </div>
                                            <div className="row align-center">
                                                <div className="col-1">
                                                    <span>02.</span>
                                                </div>

                                                <div className="col-5">
                                                    <p>Definition of testing scope and release specification for the European Union.</p>
                                                </div>
                                            </div>
                                            <div className="row align-center">
                                                <div className="col-1">
                                                    <span>03.</span>
                                                </div>

                                                <div className="col-5">
                                                    <p>Review of manufacturing and testing records, checking for compliance with EU-GMP</p>
                                                </div>
                                            </div>
                                            <div className="row align-center">
                                                <div className="col-1">
                                                    <span>04.</span>
                                                </div>

                                                <div className="col-5">
                                                    <p>Receipt of the medication, Incoming inspection and storage under quarantine.</p>
                                                </div>
                                            </div>
                                            <div className="row align-center">
                                                <div className="col-1">
                                                    <span>05.</span>
                                                </div>

                                                <div className="col-5">
                                                    <p>Sampling and batch testing.</p>
                                                </div>
                                            </div>
                                            <div className="row align-center">
                                                <div className="col-1">
                                                    <span>06.</span>
                                                </div>

                                                <div className="col-5">
                                                    <p>Release decision criteria.</p>
                                                </div>
                                            </div>
                                            <div className="row align-center">
                                                <div className="col-1">
                                                    <span>07.</span>
                                                </div>

                                                <div className="col-5">
                                                    <p>CoA (Certification of Analysis), CoC (Certification of Compliance) with EU-GMP Guidelines.</p>
                                                </div>
                                            </div>
                                            <div className="row align-center">
                                                <div className="col-1">
                                                    <span>08.</span>
                                                </div>

                                                <div className="col-5">
                                                    <p>Archiving of retained samples and documentation.</p>
                                                </div>
                                            </div>
                                        </FadeIn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="white-section">
                        <div className="container">
                            <div className="inset-container">
                                <div className="title-container">
                                    <FadeIn>
                                        <SecondaryText
                                            text    = "Our Clients"
                                            white   = {true}
                                        />
                                        
                                        <Title
                                            text        = "Industry Leaders That Have Trusted Us"
                                            titleType   = "h2"
                                        />
                                    </FadeIn>
                                </div>
                            </div>

                            <FadeIn>
                                <div className="brand-icon-cont pt-md">
                                    <div className="logo-container">
                                        <img src={logo1} alt="Supreme"/>
                                    </div>
                                    
                                    <div className="logo-container">
                                        <img src={logo2} alt="Hoshi"/>
                                    </div>
                                    
                                    <div className="logo-container">
                                        <img src={logo3} alt="Emmac"/>
                                    </div>
                                </div>
                            </FadeIn>
                        </div>
                    </section>

                    <FooterCta />

                    <Footer/>

                    {/* <ImageText
                        secondary       = "About The Company"
                        whiteSec        = {true}
                        text            = {"Cannanalytica is a Malta and London based consultancy firm specialising in the Global medical cannabis industry."}
                        content         = {"We offer our regulatory, EU GMP certification and business development consultancy services to investors and Licensed Producers who are currently present or planning to enter into the medical cannabis space. Our Team of business executives, regulatory experts and QPs/QAs with vast experience in the pharma industry are perfectly placed to advise our clients on medical cannabis projects within Europe."}
                        image           = {[{url: testImg}]}
                        imageLeft       = {false}
                        overlapTitle    = {false}
                        containerFit    = {false}
                        buttonLink      = "contact"
                        buttonText      = "Get in Touch"
                        buttonClasses   = "hero-button text-button standard-hero"
                    /> */}
            </div>     
        );
    }
}

export default WhyMaltaPage;