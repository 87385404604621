import { Component } from 'react';
import {
	withRouter
} from 'react-router-dom';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            setTimeout( function() {
                window.scrollTo(0, 0);
            }, 1000);
            // console.log("Test");
            // console.log(prevProps.location);
        }
    }
  
    render() {
        return this.props.children
    }
}
  
export default withRouter(ScrollToTop)