import React, { Component } from 'react';

// import SimplexNoise         from "simplex-noise";
import Title                from "../typography/titleSection";
import LetterAnim           from "../../animation/letterAnim";
import SecondaryText from '../typography/secondaryText';
// import bgImg                from "../../../assets/images/bg3d.jpg"

class HeroPageShift extends Component {

    constructor(props) {
        super(props);

        this.handleScroll     = this.handleScroll.bind(this);

        this.state = {
            scroll: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(){
        this.setState({
            scroll: window.pageYOffset
        })
    }

    render() {
        const scrollVanish = (this.state.scroll > 100) ? "scroll-vanish " : "";
        return (
            <div>
                <div className={"hero-container-text " + ( (this.props.fullHeight) ? "screen-height " : "" ) + ( (this.props.classes) ? this.props.classes : "" )}>               
                    <div className="inner-hero-shift">
                        {/* <div className="hero-bg-text"> */}
                            {/* <span>{this.props.pageName}</span> */}
                            {/* <LetterAnim animate={ this.props.pageName }>{this.props.pageName}</LetterAnim> */}
                        {/* </div> */}

                        <div className={"title-section-cont container "  + ( (this.props.fullHeight) ? "screen-height " : "" ) }>
                            <div className={"inner-hero " + ((this.props.image) ? "image-hero" : "")}>
                                <Title
                                    secondary   = {this.props.pageName}
                                    text        = {this.props.text}
                                    titleType   = "h1"
                                />

                                { ( this.props.image ) ? (
                                    <div className="hero-image bg-img">
                                        <div className="image-wrapper">
                                            <div className="image-cont">
                                                <img src={this.props.image} alt={this.props.pageName} />
                                            </div>
                                        </div>
                                    </div>
                                ) : "" }

                                {
                                    (!this.props.noScroll) &&
                                    <div className={ "vanish-cont " + scrollVanish }>
                                        <div onClick={() => window.scroll({
                                            top: window.innerHeight, 
                                            left: 0, 
                                            behavior: 'smooth'
                                        })} className="scroll-down-cont ">
                                            <div className="alt-scroll-circle">
                                                <div className="alt-arrow"></div>
                                            </div>
                                            <SecondaryText
                                                text    = "Scroll Down"
                                                white   = {true}
                                            />
                                        </div>
                                    </div>
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"hero-container screen-height fixed " + ( (this.props.classes) ? this.props.classes : "" )}>
                    <div className="inner-hero-shift">
                        <div className="hero-bg-text">
                            {/* <span>{this.props.pageName}</span> */}
                            <LetterAnim animate={ this.props.pageName }>{this.props.pageName}</LetterAnim>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default HeroPageShift;
